租赁信息
<style lang="scss" scoped>
#leftTable {
  padding: 0.2rem;
  height: 100%;
  min-width: 7rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
  }
  .text {
    // color: #c3cbde;
    font-size: 0.25rem;
    font-weight: bold;
    color: #fff;
  }
  #parent::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  #parent {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
  }
  #parent {
    width: 100%;
    .item {
      height: 19%;
      width: 100%;
      border-bottom: 0.008rem solid rgb(24, 69, 229);
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      color: #fff;
    }
    .item.title {
      height: 25%;
      font-size: 0.23rem;
      // color: rgb(24, 69, 229);
      font-weight: 600;
      span {
        width: 20%;
        text-align: center;
      }
    }
    .item.info {
      div {
        height: 25%;
        // color: rgb(24, 69, 229);
        width: 20%;
        text-align: center;
        font-size: 0.21rem;
      }
      .name {
        font-size: 0.21rem;
      }
      .total,
      .rent {
        // font-size: 20px;
        .num-total,
        .num-rent {
          margin-bottom: 10px;
        }
      }
      .unit {
        font-size: 0.18rem;
        margin-left: 0.12rem;
      }
    }
  }
}
</style>
<template>
  <div id="leftTable">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2" style="height: 10%">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <template>
            <span class="fs-xl text mx-2">租赁信息</span>
          </template>
          <!-- <dv-decoration-1 style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            " /> -->
        </div>
      </div>
      <div id="parent" style="height: 90%;padding:0.2rem 0.8rem 0 0.8rem">
        <div class="item title">
          <span>租赁信息</span>
          <span>总量</span>
          <span>当前已出租</span>
          <span>本月出租率</span>
          <span>环比</span>
          <span>本月退租</span>
        </div>
        <div v-for="item in infoData" :key="item.name" class="item info">
          <div class="name">{{ infoName(item.name) }}</div>
          <div class="total">
            <p class="num-total">
              {{ total(item) }}<span class="unit" v-if="item.name === 'BLUE_APARTMENT' ">间</span>
              <span class="unit" v-else>平方米</span>
            </p>
          </div>
          <div class="rent">
            <p class="num-rent">
              {{ rented(item) }}
            </p>
          </div>
          <div class="last-rate">
            {{ item.nowMonthRentRate }} %
          </div>
          <div class="current-rate" v-html="rate(item.chainRate)">
          </div>
          <div class="current-rate">
            {{item.name ==='BLUE_APARTMENT'?item.nowMonthCanceledCount+'间':item.nowMonthCanceledArea+'平方米'}}
            <!-- {{item.nowMonthCanceledCount}}间 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/xhr/rent";
export default {
  name: "rentLabel",
  props: ['infoData'],
  data () {
    return {
      // infoData: [],
    };
  },
  mounted () {
    // this.getData();
  },
  methods: {
    getData () {
      api.rentInformation().then((res) => {
        if (res.data.code === "200") {
          // console.log(res.data.data, '表格');
          let data = res.data.data;
          this.infoData = data.curLeaseInfo;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 项目名字
    infoName (name) {
      if (name === "BLUE_APARTMENT") {
        return "蓝领";
      } else if (name === "OFFICE") {
        return "办公";
      } else if (name === "SHOP") {
        return "商铺";
      }
    },
    // 总量
    total (item) {
      if (item.name === 'BLUE_APARTMENT' || item.name === 'SHOP') {
        if (item.totalCount !== null) {
          return item.totalCount
        } else {
          return '0'
        }
      } else {
        if (item.totalArea !== null) {
          return item.totalArea
        } else {
          return '0'
        }
      }
    },
    // 当前已出租
    rented (item) {
      if (item.name === 'BLUE_APARTMENT' || item.name === 'SHOP') {
        if (item.rentedCount !== null) {
          return item.rentedCount
        } else {
          return '0'
        }
      } else {
        if (item.rentedArea !== null) {
          return item.rentedArea
        } else {
          return '0'
        }
      }
    },
    // 同比环比
    rate (val) {
      let html
      if (val > 0) {
        html = val + ' <span style="color: green;">%⬆</span>'
      } else if (val < 0) {
        html = val * -1 + ' <span style="color: red;">%⬇</span>'
      } else {
        html = val + ' <span>%</span>'
      }
      return html
    }
  },
};
</script>

