<template>
  <div id="rightProvince">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-pie"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">{{projectName || ''}}区域资产信息</span>
          <!-- <dv-decoration-3 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" /> -->
        </div>
      </div>
      <!-- <div class="d-flex jc-center">
                <dv-charts style="width:3.25rem;height:3.25rem;margin-top: .5rem;" class="ring-charts"
                           :option="options"/>
            </div> -->
      <div class="histogram-info">
        <!-- <div id="myChartsRight"></div> -->
        <div class="assets-info" v-if="regionLeaseInfoList.length">
          <div class="asset-bg" v-for="(item,index) in regionLeaseInfoList" :key="index">
            <p class="num">{{item.value}}<span>{{item.name === '泊位'?'个':item.name ==='安置房'?'套':item.name ==='商铺'?'平方':item.name ==='蓝领公寓'?'间':item.name==='其他'?'平方':'套'}}</span></p>
            <p class="desc">{{item.name}}</p>
          </div>
          <!-- <div class="asset-bg">
            <p class="num">{{}}<span>平方</span></p>
            <p class="desc">商铺</p>
          </div>
          <div class="asset-bg">
            <p class="num">{{}}<span>平方</span>
            </p>
            <p class="desc">办公</p>
          </div>
           <div class="asset-bg">
            <p class="num">{{assetBasicInfo.parkUsableArea}}<span>个</span></p>
            <p class="desc">泊位</p>
          </div>
          <div class="asset-bg">
            <p class="num">{{}}<span>平方</span></p>
            <p class="desc">其他</p>
          </div> -->
        </div>
        <p class="empty" v-if="!regionLeaseInfoList.length">暂无信息</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "rightProvince",
  data () {
    return {
      myChartDrawer: null,
      emptyText: false
    }
  },
  mounted () {
    // let _this = this
    // window.addEventListener('resize', function () {
    //   if (_this.resizeTimer) clearTimeout(_this.resizeTimer);
    //   _this.resizeTimer = setTimeout(function () {
    //     _this.myChartDrawer.resize();
    //   }, 100)
    // })
    // this.drawRate();
  },
  props: ['regionLeaseInfoList', 'projectName'],
  methods: {
    drawRate (val) {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("myChartsRight")
      );
      var option = {
        title: {

        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '概况',
            type: 'pie',
            radius: '70%',// 饼图大小
            center: ['50%', '60%'],// 饼图位置
            data: val,
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorlist = ['#DE2425', '#2CF9DC', '#178BF9', '#FDDC55', '#F47D7A', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                  return colorlist[params.dataIndex];
                },
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              normal: {
                formatter: '{b}\n{d}%',
                textStyle: {
                  fontSize: 15, //指示文字
                }
              }
            }
          }
        ]
      };
      myChartDrawer.setOption(option);
    },
    draw () {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("myChartsRight")
      );
      this.myChartDrawer = myChartDrawer
      var option = {
        title: {
          // text: '堆叠区域图'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {

        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['类目一', '类目二', '类目三', '类目四', '类目五'],
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 800,
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            },

          },

        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              // show: false,// 取消刻度线
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            }, //
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 800,
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            },
          }
        ],
        series: [
          {
            type: 'line',
            stack: '总量',
            areaStyle: {},
            data: [120, 132, 101, 134, 90, 230, 210],
            smooth: true,// 线条平滑
            symbol: 'circle',// 折线点实心
            symbolSize: 6,// 折线点大小
            itemStyle: {
              borderWidth: 2,
              color: '#2AE9F2'
            },
            lineStyle: {
              color: '#2AE9F2'
            },
            areaStyle: {
              color: {
                type: 'linear',
                // x: 0,
                // y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgb(42,233,242)' // 0% 处的颜色
                }, {
                  offset: 0.5, color: 'rgb(42,233,242,.5)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgb(42,233,242,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            }
          },


        ]
      };
      myChartDrawer.setOption(option);
    },
  }
}
</script>

<style lang="scss" scoped>
#rightProvince {
  padding: 0.2rem;
  height: 100%;
  min-width: 7rem;
  border-radius: 0.0625rem;

  .bg-color-black {
    width: 100%;
    height: 100%;
    border-radius: 0.125rem;
  }
  .text {
    font-size: 0.25rem;
    font-weight: bold;
    color: #fff;
  }
}
.d-flex.pt-2 {
  height: 6%;
}
.histogram-info {
  height: 94%;
  width: 100%;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}
#myChartsRight {
  top: -0.25rem;
  width: 90%;
  height: 100%;
  // min-height: 220px;
  clear: both;
  margin: 0px auto;
}
.empty {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.4rem;
}
.assets-info {
  padding: 0.3rem 0.3rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}
.assets-info::after {
  content: "";
  width: 32%;
}
.asset-bg {
  // box-shadow: #2ae9f2 0px 0px 0.15rem inset;
  border: 0.03rem solid #2ae9f2;
  border-radius: 0.05rem;
  background-color: #1d1e2f;
  width: 32%;
  height: 50%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-content: center;
  margin-bottom: 0.15rem;
  .num {
    font-size: 0.35rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
    span {
      color: #808080;
      font-size: 0.2rem;
    }
  }
  .desc {
    width: 100%;
    line-height: 100%;
    background: linear-gradient(#2ae9f2, #1471b7);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
