<template>
  <div id="rightProvince">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-pie"></icon>
        </span>
        <div class="text d-flex"><span class="fs-xl text mx-2">{{projectName || ''}}泊位数</span></div>
      </div>
      <div class="histogram-info" v-if="parkNumber && pspn">
        <div id="myChartsRight" ref="myChartsRight"></div>
        <div class="number_car">
          <p class="p1">总数<span> {{parkNumber}}</span></p>
          <p class="p2">剩余<span>{{pspn1}}</span></p>
        </div>
      </div>
      <div class="myChartsRight" v-if="!parkNumber &&!pspn">暂无信息</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rightProvince",
  data () {
    return {

    }
  },
  props: ["pspn", "parkNumber", 'projectName'],
  mounted () {
    this.$nextTick(() => {
      this.drawA();
    })
  },
  // watch: {
  //   pspn (val) {
  //     console.log(val);
  //   }
  // },
  computed: {
    pspn1 () {
      let pspn = this.pspn;
      if (pspn < 0) {
        pspn = 0
      }
      return pspn
    }
  },
  methods: {
    drawA (a, b) {
      if (b < 0) {
        b = 0
      }
      let c = a - b
      let lv = (c / a * 100).toFixed(2)
      if (lv === 'NaN') {
        lv = 0
      }
      if (c > a) {
        c = a
      } else {
        c = a - b
      }
      // 实例化echarts对象
      let refcharts = this.$refs.myChartsRight
      if (refcharts) {
        // 实例化echarts对象
        let myChartDrawer = this.$echarts.init(
          // document.getElementById("myChartsRight")
          myChartsRight
        );

        // 绘制环形 7CCFD3 29D9E8 299DC0 285283
        var option = {
          series: [
            {
              type: "pie",
              radius: ["60%", "80%"],
              center: ["60%", "50%"],
              data: [
                {
                  name: "出租率",
                  value: c,
                  itemStyle: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 1,
                      x2: 0,
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#356eff", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#9bfcaf", // 100% 处的颜色
                        },
                      ],

                      global: false, // 缺省为 false
                    },
                  },
                  label: {
                    show: true, //单独显示该数据项
                    formatter: lv + '%',
                    color: "#49c3e3", // 100% 处的颜色
                    fontSize: '25',
                    fontWeight: 'bold'
                  },
                },
                {
                  value: (a - c),
                  itemStyle: {
                    color: "#ccc",
                  },
                  label: {
                    emphasis: {
                      show: false,
                    },
                  },
                },
              ],
              itemStyle: {
                normal: {},
              },
              label: {
                normal: {
                  //默认不显示数据
                  // formatter: lv  + '%',
                  show: false,
                  position: "center",
                },
                color: "#fff",
              },
            },
          ],
        };
        myChartDrawer.setOption(option);
      } else {

      }
    },
  }
}
</script>

<style lang="scss" scoped>
#rightProvince {
  padding: 0.2rem;
  // height: 5.125rem;
  // height: 4rem;
  height: 100%;
  min-width: 7rem;
  border-radius: 0.0625rem;

  .bg-color-black {
    // padding: 0.3rem;
    // height: 4.8125rem;
    width: 100%;
    height: 100%;
    border-radius: 0.125rem;
    display: flex;
    flex-direction: column;
  }
}
.text {
  font-size: 0.25rem;
  font-weight: bold;
  color: #fff;
  width: 100%;
  text-align: left;
  // border-bottom: 0.03rem solid #ffffff;
  padding-bottom: 0.12rem;
}
.d-flex.pt-2 {
  height: 6%;
}
.histogram-info {
  height: 94%;
  width: 100%;
  display: flex;
  //  background-color: #fff;
}
#myChartsRight {
  width: 60%;
  // min-height: 220px;
  clear: both;
  box-sizing: border-box;
  // margin: 0px auto;
}
.number_car {
  flex: 1;
  // background-color: #443422;
  display: flex;
  flex-direction: column;
  //  align-items: center;

  .p1 {
    padding-bottom: 0.2rem;
    flex: 1;
    font-size: 0.4rem;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    // line-height: .3rem;
    // justify-content: center;
  }
  .p2 {
    padding-top: 0.2rem;
    flex: 1;
    font-size: 0.4rem;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // line-height: .3rem;
    // justify-content: center;
  }
}
.myChartsRight {
  display: flex;
  width: 100%;
  height: 100%;
  //   clear: both;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  // top: 40%;
  text-align: center;
  line-height: 100%;
  font-size: 0.5rem;
  font-weight: 400;
  color: #fff;
}
</style>
