<template>
  <div id="centerForm">
    <div class="menu-btn">
      <ul>
        <li @click="changeTab('INDEX')" :class="tabName === 'INDEX'? 'active':''">主页</li>
        <li @click="changeTab('RENT')" :class="tabName === 'RENT'? 'active':''">租赁信息</li>
        <li @click="changeTab('ARRANGE')" :class="tabName === 'ARRANGE'? 'active':''">安置房</li>
        <li @click="changeTab('BLUE')" :class="tabName === 'BLUE'? 'active':''">蓝领公寓</li>
         <li @click="changeTab('SHOP')" :class="tabName === 'SHOP'? 'active':''">商铺</li>
        <li @click="changeTab('PARK')" :class="tabName === 'PARK'? 'active':''">停车场</li>
       
      </ul>
    </div>
    <!-- <el-form :inline="true" :model="formInline" :rules="formInlineRules" ref="formInline"  size="mini"> -->
    <!-- <el-form-item label="年度" prop="year">
                <el-select v-model="formInline.year" placeholder="年度">
                    <el-option
                            v-for="item in yearList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item> -->
    <!-- <el-form-item label="任务" prop="tdId">
                    <el-select v-model="formInline.tdId" placeholder="任务">
                        <el-option
                                v-for="item in tasksList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.value"
                        ></el-option>
                    </el-select>
            </el-form-item> -->
    <!-- <el-form-item label="期数" prop="phase">
                <el-select v-model="formInline.phase" placeholder="期数">
                    <el-option
                            v-for="item in phaseList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item> -->
    <!-- <el-form-item>
                <el-button type="primary">查询</el-button>
            </el-form-item> -->
    <!-- </el-form> -->
  </div>
</template>

<script>

export default {
  name: "centerForm",
  data () {
    return {
      tabName: 'INDEX',
      yearList: [{
        id: 1,
        label: '2019',
        value: '2019'
      }, {
        id: 2,
        label: '2020',
        value: '2020'
      }],
      formInline: {
        tdId: '',
        phase: '',
        year: 2020,
      },
      tasksList: [{
        id: 1,
        label: '任务一',
        value: 'task1'
      }, {
        id: 2,
        label: '任务二',
        value: 'task2'
      }],
      phaseList: [{
        id: 1,
        label: '期数一',
        value: 'phase1'
      }, {
        id: 2,
        label: '期数二',
        value: 'phase2'
      }],
      formInlineRules: {
        year: [
          { required: true, message: '请选择年度', trigger: 'change' }
        ],
        tdId: [
          { required: true, message: '请选择任务', trigger: 'change' }
        ],
        phase: [
          { required: true, message: '请选择期数', trigger: 'change' }
        ],
      },
    }
  },

  methods: {
    changeTab (name) {
     // console.log(name, '子组件');
      this.tabName = name
      this.$emit('tab', name)
    }
  }
}
</script>

<style lang="scss" scoped>
#centerForm {
  height: 100%;
  ::v-deep.el-form {
    padding: 0.3rem 0.5rem;
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__label {
        color: #fff;
        padding-right: 0.1rem;
      }
    }
  }
}
.menu-btn {
  width: 100%;
  height: 100%;
  overflow: hidden;
  ul {
    height: 100%;
  }
  li {
    height: 100%;
    width: 16%;
    float: left;
    font-size: 0.3rem;
    // text-align: center;
    justify-content: center;
    // line-height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .active {
    color: #257dff;
    font-weight: bold;
  }
}
</style>
