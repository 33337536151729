<template>
  <div id="china_map_box">
    <!-- 首页图标显示隐藏 暂时关闭功能  -->
    <!-- <el-button type=" primary" size="mini" class="back" @click="back" v-if="deepTree.length > 1">返回</el-button> -->
    <!-- <div class="info" @click="openLabel" v-if="!isShow && currentTab==='INDEX'">查看详情</div>
    <div class="info" @click="colseLabel" v-if="isShow && currentTab==='INDEX'">隐藏详情</div> -->
    <!-- <el-button class="info" type="primary" size="small">查看详情</el-button> -->
    <div class="infos" v-if="currentTab!=='INDEX' && infoShow">
      <p class="name">{{currentInfo.community}}</p>
      <div v-if="currentTab==='BLUE'">
        <p class="desc"><span>总间数:</span><span class="num">{{currentInfo.detail.summaryCount}}</span></p>
        <p class="desc"><span>已出租:</span><span class="num">{{currentInfo.detail.leasedCount}}</span></p>
      </div>
      <div v-if="currentTab==='SHOP'">
        <p class="desc"><span>总面积:</span><span class="num">{{currentInfo.detail.summaryArea}}</span></p>
        <p class="desc"><span>空置面积:</span><span class="num">{{currentInfo.detail.unusedArea}}</span></p>
        <p class="desc"><span>已出租面积:</span><span class="num">{{currentInfo.detail.leasedArea}}</span>
        </p>
      </div>
      <div v-if="currentTab==='ARRANGE'">
        <p class="desc"><span>空置套数:</span><span class="num">{{currentInfo.detail.arrangePlantSets}}</span></p>
        <p class="desc"><span>蓝领套数:</span><span class="num">{{currentInfo.detail.bluePlantSets}}</span></p>
        <p class="desc"><span>借用套数:</span><span class="num">{{currentInfo.detail.borrowPlantSets}}</span></p>
        <p class="desc"><span>其他套数:</span><span class="num">{{currentInfo.detail.otherPlantSets}}</span></p>
        <p class="desc"><span>区域总套数:</span><span class="num">{{currentInfo.detail.allPlantSets}}</span></p>
        <p class="desc"><span>已安置套数:</span><span class="num">{{currentInfo.detail.plantSets}}</span></p>
        <p class="desc"><span>预留安置套数:</span><span class="num">{{currentInfo.detail.allotPlantSets}}</span></p>
      </div>
      <div v-if="currentTab==='PARK'">
        <p class="desc"><span>总数:</span><span class="num">{{currentInfo.detail.slotTotal}}</span></p>
        <p class="desc"><span>剩余:</span><span class="num">{{currentInfo.detail.remainTotal}}</span></p>
        <p class="desc"><span>已出租:</span><span class="num">{{currentInfo.detail.rentedTotal}}</span></p>
      </div>

    </div>
    <div class="echarts" v-if='test'>
      <div id="map"></div>
      <p style="color:white"></p>
    </div>
  </div>
</template>

<script>
import { getChinaJson, getProvinceJSON, getCityJSON } from "../api/get-json";
import { cityProvincesMap } from "../config/cityProvincesMap";
import { mapOption } from "../config/mapOption";
import { temp } from '../../public/map/province/330100'
import posApi from '@/xhr/position'
import {
  blueUrl, parkUrl,
  shopUrl,
  arrangeUrl,
  indexUrl
} from './icons/index'
var geoCoordMap = {
  "三墩镇": [120.05859375, 30.322804106276315],
  // "三墩镇2": [120.05959375, 30.322804106276315],
  "蒋村街道": [120.06855010986328, 30.282788098216884],
  "文新街道": [120.09498596191406, 30.287235127825305],
  "古荡街道": [120.10871887207031, 30.283084573128505],
  "翠苑街道": [120.12348175048827, 30.283677520264256],
  "西溪街道": [120.14030456542969, 30.284270463816537],
  "北山街道": [120.14202117919923, 30.253729211980726],
  "灵隐街道": [120.11592864990234, 30.260253384089417],
  "留下街道": [120.05790710449219, 30.235933812981585],
  "西湖街道": [120.10494232177734, 30.214278399476623],
  "转塘街道": [120.05241394042967, 30.166797806444716],
  "双浦镇": [120.09876251220703, 30.110087890214736],
};
var convertData = function (data) {
  var res = [];
  for (var i = 0; i < data.length; i++) {
    var geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value)
      });
    }
  }
  return res;
};
export default {
  name: "china",
  components: {},
  props: ['currentTab'],
  data () {
    return {
      parkData: [],// 图标详情数据
      arrangeData: [],
      shopData: [],
      blueData: [],
      currentInfo: {},//当前触发详情
      infoShow: false, // 图标详情显示隐藏
      itemStyle: {
        color: '#2ae9f2'
      },
      itemStyle2: {
        color: '#46DBAD'
      },
      itemStyle3: {
        color: '#2ae9f2'
      },
      highStyle: {
        show: true,
        rich: {
          a: {
            width: 16,
            height: 2,
            backgroundColor: '#2ae9f2',
          },
          b: {
            color: 'white',
            fontSize: 11,
            borderColor: '#2ae9f2',
            borderWidth: '0.5',
            padding: [5, 8],
            borderRadius: 3,
            backgroundColor: 'transparent',
          },
          g: {
            color: 'white',
            fontSize: 11,
            borderColor: '#2ae9f2',
            borderWidth: '0.5',
            padding: [5, 8],
            borderRadius: 3,
            backgroundColor: 'transparent',
          },
          c: {
            color: 'white',
            fontSize: 11,
            padding: [0, 0, 0, 4],
            lineHeight: 16,
          },
          d: {
            color: 'white',
            fontSize: 11,
            padding: [0, 20, 0, 20],
            lineHeight: 16,
          },
          e: {
            color: 'white',
            fontSize: 11,
            padding: [0, 0, 0, 0],
            lineHeight: 16,
          },
          f: {
            color: 'white',
            fontSize: 11,
            padding: [0, 26, 0, 0],
            lineHeight: 16,
          }
        },
        distance: 5,
      },
      highStyle2: {
        show: true,
        rich: {
          a: {
            width: 16,
            height: 2,
            backgroundColor: '#46DBAD',
          },
          b: {
            color: 'white',
            fontSize: 12,
            borderColor: '#46DBAD',
            borderWidth: '1',
            padding: [5, 8],
            borderRadius: 3,
            backgroundColor: 'transparent',
          },
          g: {
            color: 'white',
            fontSize: 11,
            borderColor: '#2ae9f2',
            borderWidth: '0.5',
            padding: [5, 8],
            borderRadius: 3,
            backgroundColor: 'transparent',
          },
          c: {
            color: 'white',
            fontSize: 11,
            padding: [0, 0, 0, 4],
            lineHeight: 16,
          },
          d: {
            color: 'white',
            fontSize: 11,
            padding: [0, 20, 0, 20],
            lineHeight: 16,
          },
          e: {
            color: 'white',
            fontSize: 11,
            padding: [0, 0, 0, 0],
            lineHeight: 16,
          },
          f: {
            color: 'white',
            fontSize: 11,
            padding: [0, 26, 0, 0],
            lineHeight: 16,
          }
        },
        distance: 5,
      },
      highStyle3: {
        show: true,
        rich: {
          a: {
            width: 16,
            height: 2,
            backgroundColor: '#2ae9f2',
          },
          b: {
            color: 'white',
            fontSize: 11,
            borderColor: '#2ae9f2',
            borderWidth: '0.5',
            padding: [5, 8],
            borderRadius: 3,
            backgroundColor: 'transparent',
          },
          g: {
            color: 'white',
            fontSize: 11,
            borderColor: '#2ae9f2',
            borderWidth: '0.5',
            padding: [5, 8],
            borderRadius: 3,
            backgroundColor: 'transparent',
          },
          c: {
            color: 'white',
            fontSize: 11,
            padding: [0, 0, 0, 4],
            lineHeight: 16,
          },
          d: {
            color: 'white',
            fontSize: 11,
            padding: [0, 20, 0, 20],
            lineHeight: 16,
          },
          e: {
            color: 'white',
            fontSize: 11,
            padding: [0, 0, 0, 0],
            lineHeight: 16,
          },
          f: {
            color: 'white',
            fontSize: 11,
            padding: [0, 26, 0, 0],
            lineHeight: 16,
          }
        },
        distance: 5,
      },
      test: true,
      isShow: false,
      fhourTime: null,
      labelShow: true,
      posData: [
      ],
      chart: null, // 实例化echarts
      provincesMap: cityProvincesMap.provincesMap, // 省拼音，用于查找对应json
      provincesCode: cityProvincesMap.provincesCode, // 市行政区划，用于查找对应json
      areaMap: cityProvincesMap.areaMap, // 省行政区划，用于数据的查找，按行政区划查数据
      mapData: [], // 当前地图上的地区
      option: { ...mapOption.basicOption }, // map的相关配置
      deepTree: [], // 点击地图时push，点返回时pop
      areaName: '西湖区', // 当前地名
      areaCode: "330100", // 当前行政区划
      // areaLevel: "country", // 当前级别
      // hightName: "西湖区222",
      // hightNames: ["西湖区111", "西湖区222", "西湖区333", "西湖区444"],
      // time: 2000,
      iconSize: [],
      blueIcon: blueUrl,
      arrangeIcon: arrangeUrl,
      shopIcon: shopUrl,
      parkIcon: parkUrl,
      currentIcon: '',
    };
  },
  watch: {
    currentTab: {
      handler: function (newVal, oldVal) {
        if (newVal === 'PARK') {
          this.isShow = true
          this.iconSize = [16, 22]
          this.currentIcon = this.parkIcon
          this.getParkPos()
        } else if (newVal === 'BLUE') {
          this.isShow = true
          this.iconSize = [20, 26]
          this.currentIcon = this.blueIcon
          this.getBluePos()
        } else if (newVal === 'SHOP') {
          this.isShow = true
          this.iconSize = [22, 26]
          this.currentIcon = this.shopIcon
          this.getShopPos()
        } else if (newVal === 'INDEX') {
          this.isShow = false
          this.iconSize = [22, 26]
          this.currentIcon = this.arrangeIcon
          this.getAllPos()
        } else if (newVal === 'ARRANGE') {
          this.isShow = true
          this.iconSize = [22, 26]
          this.currentIcon = this.arrangeIcon
          this.getAssetPos()
        }
        if (newVal !== 'INDEX') {
          this.highStyle = this.highStyle2
          this.itemStyle = this.itemStyle2
        } else {
          this.itemStyle = this.itemStyle3
          this.highStyle = this.highStyle3
        }
      },
      immediate: true
    },
  },
  mounted () {
    this.$nextTick(() => {
      // this.initEcharts();
    })

  },
  destroyed () {
    for (let i = 0; i < 10; i++) {
      clearInterval(i)
    }
    // if (this.chart.highlight) {
    this.chart.off('highlight')
    // }
  },
  methods: {
    // 首页图标显示隐藏 暂时关闭功能 
    // openLabel () {
    //   // window.clearInterval(this.fhourTime)
    //   this.isShow = true
    //   this.initEcharts()
    // },
    // colseLabel () {
    //   // window.clearInterval(this.fhourTime)
    //   this.isShow = false
    //   this.initEcharts()
    // },
    // 处理定位数据
    changePosData (data, type) {
      if (data) {
        let handleData = data
        this.posData = []
      }
      // 控制地图范围
      let temp = data.filter(el => el.longitude < 120.155 && el.longitude > 120.007 && el.latitude > 30.1949 && el.latitude < 30.359)
      data = temp
      if (this.currentTab === 'INDEX') {
        // this.labelShow = false
        // 排序
        data.sort((a, b) => {
          return b.latitude - a.latitude
        })
        //latitude
        // 如果离得太近修改经度
        // data.forEach((el, index) => {
        //   if (index < data.length - 1) {
        //     if (el.longitude - data[index + 1].longitude <= 0.003 && el.latitude - data[index + 1].latitude == 0) {
        //       data[index + 1].longitude = Number(data[index + 1].longitude) + 0.003
        //     }
        //   }
        // })

        // 处理指示的左右分级
        data.forEach((el, index) => {
          if (index < data.length - 1) {
            if (el.latitude - data[index + 1].latitude <= 0.005378 && Math.abs(el.longitude - data[index + 1].longitude) <= 0.04) {
              if (data[index].pos !== 'right') {
                data[index + 1].pos = 'right'
              } else {
                data[index + 1].pos = ''
              }
            }
          }
        })
        // 添加数据
        data.forEach(el => {
          if (el.pos === 'right') {
            let obj = {
              label: {
                // formatter: [
                //   '{a|}',
                //   '{b|{c}}'
                // ].join(''),
                // offset: [18, 10],
                // borderWidth: '2',
                // borderColor: '#2ae9f2',
                // padding: [2, 4, 2, -16],
                // formatter: function () {
                //   return ['{a|}', '{c|' + el.community + '}', '{d|' + el.text + '}'].join('')
                // },
                formatter: function () {
                  return ['{a|}', '{g|' + el.community + el.text + '}'].join('')
                },
                position: 'right',
              },
              // symbolSize: [22, 22],
              // symbol: this.testUrl,
              value: el.community,
              coord: [el.longitude, el.latitude]
            }
            this.posData.push(obj)
          } else {
            let obj = {
              label: {
                position: 'left',
                // formatter: [
                //   '{b|{c}}',
                //   '{a|}'
                // ].join(''),
                // offset: [-14, -10],
                // borderWidth: '2',
                // borderColor: '#2ae9f2',
                // padding: [2, -15, 2, 4],
                // formatter: function () {
                //   return ['{e|' + el.community + '}', '{f|' + el.text + '}', '{a|}'].join('')
                // },
                formatter: function () {
                  return ['{g|' + el.community + el.text + '}', '{a|}'].join('')
                },
              },
              // symbolSize: [22, 22],
              // symbol: this.testUrl,
              value: el.community,
              coord: [el.longitude, el.latitude]
            }
            this.posData.push(obj)
          }
        })
      } else {
        this.labelShow = true
        data.sort((a, b) => {
          return b.latitude - a.latitude
        })
        data.forEach((el, index) => {
          if (index < data.length - 1) {
            if (el.latitude - data[index + 1].latitude <= 0.005378 && Math.abs(el.longitude - data[index + 1].longitude) <= 0.04) {
              if (data[index].pos !== 'right') {
                data[index + 1].pos = 'right'
              } else {
                data[index + 1].pos = ''
              }
            }
          }
        })
        this.posData = []
        data.forEach(el => {
          if (el.pos === 'right') {
            let obj = {
              label: {
                formatter: [
                  '{a|}',
                  '{b|{c}}'
                ].join(''),
                position: 'right',
              },
              name: el.communityCode,
              value: el.community,
              coord: [el.longitude, el.latitude]
            }
            this.posData.push(obj)
          } else {
            let obj = {
              label: {
                position: 'left',
                formatter: [
                  '{b|{c}}',
                  '{a|}'
                ].join(''),
              },
              name: el.communityCode,
              value: el.community,
              coord: [el.longitude, el.latitude]
            }
            this.posData.push(obj)
          }
        })
      }

      this.initEcharts()
    },
    // 主页定位数据
    getAllPos () {
      posApi.getIndexData().then(res => {
        if (res.data.code === '200') {
          if (res.data.data) {
            let temp = res.data.data.infos
            temp.forEach((el, index) => {
              let text = ''
              if (el.detail.shopValue && el.detail.shopValue > 0) {
                text += ' 铺' + el.detail.shopValue + '平方'
              }
              if (el.detail.estateValue && el.detail.estateValue > 0) {
                text += ' 宅' + el.detail.estateValue + '套'
              }
              if (el.detail.parkValue && el.detail.parkValue > 0) {
                text += ' 泊' + el.detail.parkValue + '个'
              }
              if (el.detail.blueValue && el.detail.blueValue > 0) {
                text += ' 蓝' + el.detail.blueValue + '间'
              }
              el.text = text
            })
            this.changePosData(temp)
          }
        } else {
          console.log(res.data.message);
        }
      })
    },
    // 停车场定位数据
    getParkPos () {
      return new Promise((resolve, reject) => {
        posApi.getParkData().then(res => {
          if (res.data.code === '200') {
            if (this.currentTab === 'PARK') {
              this.parkData = res.data.data.infos
              this.changePosData(res.data.data.infos, 'PARK')
              resolve()
            } else {
              resolve(res.data.data.infos)
            }
          }
        })
      })
    },
    // 安置房定位数据
    getAssetPos () {
      return new Promise((resolve, reject) => {
        posApi.getAssetData().then(res => {
          if (res.data.code === '200') {
            if (this.currentTab === 'ARRANGE') {
              this.arrangeData = res.data.data.infos
              this.changePosData(res.data.data.infos, 'ARRANGE')
              resolve()
            } else {
              resolve(res.data.data.infos)
            }
          }
        })
      })
    },
    // 蓝领定位数据
    getBluePos () {
      return new Promise((resolve, reject) => {
        posApi.getBlueData().then(res => {
          if (res.data.code === '200') {
            if (this.currentTab === 'BLUE') {
              this.blueData = res.data.data.infos
              this.changePosData(res.data.data.infos, 'BLUE')
              resolve()
            } else {
              resolve(res.data.data.infos)
            }
          }
        })
      })
    },
    // 商铺定位数据
    getShopPos () {
      return new Promise((resolve, reject) => {
        posApi.getShopData().then(res => {
          if (res.data.code === '200') {
            if (this.currentTab === 'SHOP') {
              this.shopData = res.data.data.infos
              this.changePosData(res.data.data.infos, 'SHOP')
              resolve()
            } else {
              resolve(res.data.data.infos)
            }
          }
        })
      })
    },
    // 初次加载绘制地图
    initEcharts () {
      //地图容器
      if (this.echarts) {
        this.chart.off('highlight')
      }
      // this.chart.off('highlight')
      // if (this.fhourTime) {
      //   clearInterval(this.fhourTime)
      //   this.fhourTime = null
      // }
      this.chart = this.$echarts.init(document.getElementById("map"));
      this.requestGetProvinceJSON({
        areaName: this.areaName,
        areaCode: this.areaCode,
      });
    },
    // 地图点击
    //绘制全国地图
    // 加载省级地图
    requestGetProvinceJSON (params) {
      // getProvinceJSON(params.areaCode).then((res) => {
      this.$echarts.registerMap(params.areaName, temp);
      let arr = [];
      for (let i = 0; i < temp.features.length; i++) {
        let obj = {
          name: temp.features[i].properties.name,
          areaName: temp.features[i].properties.name,
          areaCode: temp.features[i].id,
          areaLevel: "city",
          value: Math.round(Math.random()),
        };
        arr.push(obj);
      }
      this.mapData = arr;
      this.deepTree.push({
        mapData: arr,
        params: params,
      });
      this.renderMap(params.areaName, arr);
      // });
    },
    renderMap (map, data) {
      // let _that = this
      this.option.series = [
        {
          type: 'map', // 类型
          mapType: map,
          coordinateSystem: 'geo',
          // ...mapOption.seriesOption,
          data: convertData(data),
          symbolSize: 12,
          symbol: 'circle',
          // roam: false,
          nameMap: {
            'china': '西湖区'
          },
          label: {
            normal: {
              show: false,
              textStyle: {
                color: '#ccc', // 地图文字
              }
            },
            emphasis: {
              show: false,
              textStyle: {
                color: '#fff',// 鼠标划过地图文字
              }
            }
          },
          itemStyle: {
            normal: {
              borderWidth: 1, //边际线大小
              borderColor: "#1170D4", //边界线颜色
              // areaColor: "#09295b", //默认区域颜色 
              label: {
                show: true,
                textStyle: {
                  color: "rgb(249, 249, 249)",
                }
              },
            },
            emphasis: {
              borderWidth: 2, //边际线大小
              // 普通图表的高亮颜色
              color: "red",
              // borderColor: "#9AE09E", //边界线颜色
              // 地图区域的高亮颜色
              areaColor: "rgb(3, 25, 81)",

            },
          },
          select: {
            label: {
              show: false
            },
            itemStyle: {
              color: 'transparent',
            }
          },
          markPoint: {
            // silent: true,
            tooltip: {
              trigger: 'none',
            },
            position: [100, 10],
            symbol: this.currentIcon,
            symbolSize: this.iconSize,
            itemStyle: {
              color: '#2ae9f2'
            },
            label: {
              normal: {
                show: this.isShow,
                rich: {
                  a: {
                    width: 16,
                    height: 2,
                    backgroundColor: '#2ae9f2',
                  },
                  b: {
                    color: 'white',
                    fontSize: 11,
                    borderColor: '#2ae9f2',
                    borderWidth: '0.5',
                    padding: [5, 8],
                    borderRadius: 3,
                    backgroundColor: 'transparent',
                  },
                  c: {
                    color: 'white',
                    fontSize: 11,
                    padding: [0, 0, 0, 4],
                    lineHeight: 16,
                  },
                  g: {
                    color: 'white',
                    fontSize: 11,
                    borderColor: '#2ae9f2',
                    borderWidth: '0.5',
                    padding: [5, 8],
                    borderRadius: 3,
                    backgroundColor: 'transparent',
                  },
                  d: {
                    color: 'white',
                    fontSize: 11,
                    padding: [0, 20, 0, 20],
                    lineHeight: 16,
                  },
                  e: {
                    color: 'white',
                    fontSize: 11,
                    padding: [0, 0, 0, 0],
                    lineHeight: 16,
                  },
                  f: {
                    color: 'white',
                    fontSize: 11,
                    padding: [0, 26, 0, 0],
                    lineHeight: 16,
                  }
                },
                distance: 5,
              },
            },
            emphasis: {
              label: this.highStyle,
              itemStyle: this.itemStyle
            },
            data: this.posData
          },
        }
      ];
      //渲染地图
      this.chart.setOption(this.option);
      let that = this;

      var hourIndex = 0;
      var fhourTime = null;
      var times = 10000;
      fn()
      fhourTime = setInterval(fn, times);
      function fn () {
        that.chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
        that.chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: hourIndex,
          name: data[hourIndex].name
        });
        that.$emit("adress", data[hourIndex].name)
        that.chart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: hourIndex,
        });
        hourIndex++;
        if (hourIndex > 8) {
          hourIndex = 0;
        }
      }
      // 鼠标位于图标上 获取图标内容
      // that.chart.on('mouseover', function (e) {
      //   if (e.componentType === 'markPoint') {
      //     let temp = that.currentTab.toLowerCase() + 'Data'
      //     let item = that[temp].find(el => el.community === e.value)
      //     that.$nextTick(() => {
      //       that.currentInfo = item
      //       that.infoShow = true
      //     })
      //   } else {
      //     that.$nextTick(() => {
      //       that.infoShow = false
      //     })
      //   }
      // })
      // that.chart.on('highlight', function (e) {
      //   console.log(e.name, 'highlight');
      //   that.$emit("adress", e.name)
      // })
      //鼠标移入停止轮播
      that.chart.on("mousemove", function (e) {
        if (e.componentType === 'markPoint' && that.currentTab !== 'INDEX') {
          // 触发类型是markPoint时
          let temp = that.currentTab.toLowerCase() + 'Data'
          let item = that[temp].find(el => el.community === e.value)
          that.$nextTick(() => {
            that.currentInfo = item //对应详情
            that.infoShow = true //打开详情框
          })
        } else {
          that.$nextTick(() => {
            that.infoShow = false //关闭详情框
          })
        }
        if (e.componentType !== 'markPoint') {
          that.$emit("adress", e.name)
          clearInterval(fhourTime)
          that.chart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
          });
          that.chart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: e.dataIndex,
            name: data[hourIndex].areaName
          });
          // that.$emit("adress", data[hourIndex].name)
          that.chart.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: e.dataIndex
          });
        }
      })
      //鼠标移出恢复轮播
      that.chart.on("mouseout", function (e) {
        if (e.componentType === 'markPoint' && that.currentTab !== 'INDEX') {
          //鼠标移出关闭详情框
          that.$nextTick(() => {
            that.infoShow = false
          })
        }
        if (e.componentType !== 'markPoint') {
          fhourTime = setInterval(function () {
            that.chart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
            });
            that.chart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: hourIndex,
              name: data[hourIndex].name
            });
            that.$emit("adress", data[hourIndex].name)
            that.chart.dispatchAction({
              type: "showTip",
              seriesIndex: 0,
              dataIndex: hourIndex
            });
            hourIndex++;
            if (hourIndex > 8) {
              hourIndex = 0;
            }
          }, 10000);
        }
      })
    },

    // 返回
    back () {
      if (this.deepTree.length > 1) {
        this.deepTree.pop();
        let areaName = this.deepTree[this.deepTree.length - 1].params.areaName;
        let mapData = this.deepTree[this.deepTree.length - 1].mapData;
        this.$emit(
          "back-change",
          this.deepTree[this.deepTree.length - 1].params
        );
        this.renderMap(areaName, mapData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#china_map_box {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  .echarts {
    width: 0;
    flex: 1;
    background-size: 100% 100%;
    #map {
      height: 100%;
    }
  }
  .back {
    position: absolute;
    top: 0.8rem;
    right: 0.5rem;
    z-index: 999;
    padding-left: 0.12rem;
    padding-right: 0.12rem;
  }
}
.info {
  cursor: pointer;
  z-index: 100000;
  height: 0.36rem;
  position: absolute;
  right: 1.2rem;
  top: 1.3rem;
  width: 1rem;
  border: 0.03rem solid #1170d4;
  font-size: 0.16rem;
  font-weight: 800;
  color: white;
  text-align: center;
  line-height: 0.34rem;
  border-radius: 0.1rem;
  background-color: rgb(0, 0, 0, 0.3);
}
.info:hover {
  background-color: rgb(3, 25, 81);
}
.infos {
  width: 2rem;
  min-height: 2rem;
  padding: 0.15rem;
  border: 0.02rem solid #1170d4;
  border-radius: 0.1rem;
  position: absolute;
  top: 1rem;
  right: 0.95rem;
  font-size: 0.22rem;
  font-weight: 600;
  .name {
    margin-bottom: 0.2rem;
    line-height: 0.22rem;
    font-size: 0.2rem;
  }
  .desc {
    font-size: 0.16rem;
    line-height: 0.2rem;
    span:first-child {
      // display: inline-block;
      // width: 60%;
    }
  }
  .num {
    margin-left: 0.1rem;
  }
}
</style>

