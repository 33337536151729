<template>
  <div id="rentPies">
    <div class="bg-color-black">
      <div class="histogram-info">
        <div class="chart-bg">
          <div class="d-flex pt-2 pl-2">
            <span style="color:#5cd9e8">
              <icon name="chart-pie"></icon>
            </span>
            <div class="d-flex">
              <Company :company='company' />
              <span class="fs-xl text mx-2">小区公寓信息(已出租占比)</span>
            </div>
          </div>
          <div id="myChartsHome"></div>
          <p class="empty" v-if="emptyText">暂无信息</p>
        </div>
        <div class="chart-bg">
          <div class="d-flex pt-2 pl-2">
            <span style="color:#5cd9e8">
              <icon name="chart-pie"></icon>
            </span>
            <div class="d-flex">
              <Company :company='company1' />
              <span class="fs-xl text mx-2">小区商铺信息(已出租占比)</span>
            </div>
          </div>
          <div id="myChartsShop"></div>
          <p class="empty" v-if="emptyText2">暂无信息</p>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Company from '../Company'
export default {
  name: "rentPies",
  data () {
    return {
      company: '间',
      company1: '万/平方',
      myChartDrawer: null,
      myChartDrawer2: null,
      emptyText: false,
      emptyText2: false
    }
  },
  components: {
    Company
  },
  mounted () {
    this.drawHome();
    this.drawShop();
    // let _this = this
    // window.addEventListener('resize', function () {
    //   if (_this.resizeTimer) clearTimeout(_this.resizeTimer);
    //   _this.resizeTimer = setTimeout(function () {
    //     _this.myChartDrawer.resize();
    //     _this.myChartDrawer2.resize();
    //   }, 100)
    // })
  },
  methods: {
    drawHome (val) {
      if (!val) {
        this.emptyText = true
      } else {
        this.emptyText = false
      }
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("myChartsHome")
      );
      this.myChartDrawer = myChartDrawer
      var option = {
        title: {

        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}间 ({d}%)'
          // formatter: function (params) { // 鼠标划过提示语
          //       let pna = params.name +'\t\t\t'+ params.percent+'%' + '\n' +
          //        (params.value >1000?((params.value)/10000).toFixed(2):((params.value)/10000).toFixed(4))
          //       return pna;
          //     },
        },
        label: {
          color: '#fff'
        },
        series: [
          // {
          //   name: '租赁概况',
          //   type: 'pie',
          //   radius: ['45%', '70%'],// 饼图大小
          //   center: ['50%', '53%'],// 饼图位置
          //   data: val,
          //   itemStyle: {
          //     normal: {
          //       color: function (params) {
          //         var colorlist = ['#C1BF52', '#C49741', '#1989EA', '#156AE4', '#4FE0B6', '#44B079', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
          //         return colorlist[params.dataIndex];
          //       },
          //     }
          //   },
          //   emphasis: {
          //     itemStyle: {
          //       shadowBlur: 10,
          //       shadowOffsetX: 0,
          //       shadowColor: 'rgba(0, 0, 0, 0.5)'
          //     }
          //   },
          //   label: {
          //     normal: {
          //       formatter: '{b}\n{d}%',
          //       textStyle: {
          //         fontSize: 12, //指示文字
          //       }
          //     }
          //   }
          // }
          {
            data: val,
            name: "",
            type: "pie",
            radius: ['40%', '65%'],
            center: ["50%", "58%"],
            itemStyle: {
              color: function (params) {
                // var colorlist = ['#DE2425', '#2CF9DC', '#178BF9', '#FDDC55', '#F47D7A', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                var colorlist = ['#1989EA', '#156AE4', '#C1BF52', '#C49741', '#4FE0B6', '#44B079', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                // var colorlist = ['#CBD435', '#f6eeda', '#f29a8b', '#e06641', '#F47D7A']
                return colorlist[params.dataIndex];
              },
            },
            height: '100%',
            left: 'center',
            width: '100%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            label: {
              color: '#fff',
              alignTo: 'edge',
              formatter: '{name|{b}}\n{time|{c} ({d}%)}',
              // formatter: function (params) { // 鼠标划过提示语
              //   let pna = params.name +'\t\t\t'+ params.percent+'%' + '\n' +
              //    (params.value >1000?((params.value)/10000).toFixed(2):((params.value)/10000).toFixed(4))
              //   return pna;
              // },
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 20,
              // color: 'red',
              rich: {
                time: {
                  fontSize: 14,
                  color: '#fff'
                }
              }
            },
            labelLine: {
              length: 0,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout (params) {
              var isLeft = params.labelRect.x < myChartDrawer.getWidth() / 2;
              var points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;

              return {
                labelLinePoints: points
              };
            },
          },
        ]
      };
      myChartDrawer.setOption(option);
    },
    drawShop (val) {
      if (!val) {
        this.emptyText2 = true
      } else {
        this.emptyText2 = false
      }
      // 实例化echarts对象
      let myChartDrawer2 = this.$echarts.init(
        document.getElementById("myChartsShop")
      );
      this.myChartDrawer2 = myChartDrawer2
      var option = {
        title: {

        },
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)'
          formatter: function (params) { // 鼠标划过提示语
            let pna = params.name + '\t\t\t' + params.percent + '%' + '\n' +
              //  (params.value >10000?((params.value)/10000).toFixed(1):((params.value)/10000).toFixed(4))
              params.value + '平方'
            return pna;
          },
        },
        label: {
          color: '#fff'
        },
        series: [
          // {
          //   name: '租赁概况',
          //   type: 'pie',
          //   radius: ['45%', '70%'],// 饼图大小
          //   center: ['50%', '53%'],// 饼图位置
          //   data: val,
          //   itemStyle: {
          //     normal: {
          //       color: function (params) {
          //         var colorlist = ['#1989EA', '#156AE4', '#C1BF52', '#C49741', '#4FE0B6', '#44B079', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
          //         return colorlist[params.dataIndex];
          //       },
          //     }
          //   },
          //   emphasis: {
          //     itemStyle: {
          //       shadowBlur: 10,
          //       shadowOffsetX: 0,
          //       shadowColor: 'rgba(0, 0, 0, 0.5)'
          //     }
          //   },
          //   label: {
          //     normal: {
          //       formatter: '{b}\n{d}%',
          //       textStyle: {
          //         fontSize: 12, //指示文字
          //       }
          //     }
          //   }
          // }
          {
            data: val,
            name: "",
            type: "pie",
            radius: ['40%', '65%'],
            center: ["50%", "58%"],
            itemStyle: {
              color: function (params) {
                // var colorlist = ['#DE2425', '#2CF9DC', '#178BF9', '#FDDC55', '#F47D7A', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                var colorlist = ['#1989EA', '#156AE4', '#C1BF52', '#C49741', '#4FE0B6', '#44B079', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                // var colorlist = ['#CBD435', '#f6eeda', '#f29a8b', '#e06641', '#F47D7A']
                return colorlist[params.dataIndex];
              },
            },
            height: '100%',
            left: 'center',
            width: '100%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            label: {
              color: '#fff',
              alignTo: 'edge',
              // formatter: '{name|{b}}\n{time|{c} 平方}',
              formatter: function (params) { // 鼠标划过提示语
                let pna = params.name + '\n' +
                  ((params.value) / 10000).toFixed(1) + '（' + params.percent + '%）'
                return pna;
              },
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 20,
              // color: 'red',
              rich: {
                time: {
                  fontSize: 14,
                  color: '#fff'
                }
              }
            },
            labelLine: {
              length: 20,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout (params) {
              var isLeft = params.labelRect.x < myChartDrawer2.getWidth() / 2;
              var points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;

              return {
                labelLinePoints: points
              };
            },
          },
        ]
      };
      myChartDrawer2.setOption(option);
    },
  }
}
</script>

<style lang="scss" scoped>
#rentPies {
  padding: 0.2rem;
  height: 100%;
  min-width: 7rem;
  border-radius: 0.0625rem;

  .bg-color-black {
    width: 100%;
    height: 100%;
    border-radius: 0.125rem;
  }
  .text {
    // color: #c3cbde;
    font-size: 0.25rem;
    font-weight: bold;
    color: #fff;
  }
}
.d-flex.pt-2 {
  height: 6%;
}
.histogram-info {
  height: 100%;
  width: 100%;
  display: flex;
  .chart-bg {
    width: 50%;
    height: 100%;
    position: relative;
  }
  .empty {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.35rem;
  }
}
#myChartsHome {
  width: 90%;
  height: 100%;
  // min-height: 220px;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}
#myChartsShop {
  width: 90%;
  height: 100%;
  // min-height: 220px;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}
</style>