<template>
  <div id="rightCounty">
    <div class="bg-color-black">
      <div class="mainBox">
        <vue-seamless-scroll v-if="this.communityInfoMap.length" :data="this.communityInfoMap" :class-option="optionSingleHeightTime" class="seamless-warp">
          <ul class="item">
            <!-- <div class="item_div">{{projectName}}</div> -->
            <li  v-for=" item in communityInfoMap" :key="item.id">
              <h2>{{projectName}}{{item.communityName}}</h2>
              <h4 v-if="item.blueApartment">蓝领公寓<span>总套数 <i>{{item.blueApartment}}</i> 套</span></h4>
              <h4 v-if="item.shop">商铺<span>总平方数 <i>{{item.shop}}</i> 平方</span></h4>
              <h4 v-if="item.park">停车泊位<span>总泊位 <i>{{item.park}}</i> 个</span></h4>
              <h4 v-if="item.plant">安置房<span>安置 <i>{{item.plant}}</i> 套</span></h4>
              <h4 v-if="item.other">其他<span><i>{{item.other}}</i> 平方</span></h4>
              <h4 v-if="!item.shop&&!item.blueApartment&&!item.park&&!item.plant">暂无信息</h4>
            </li>
          </ul>
        </vue-seamless-scroll>
         <div v-if="!communityInfoMap.length" class="center">暂无信息</div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    //组件
    vueSeamlessScroll,
  },
  name: "rightCounty",
  data () {
    return {
      listData: [
        {
          title: "无缝滚动第九行无缝滚动第九行",
          date: "2017-12-16",
        },
      ],
    };
  },
  watch:{
    
  },
  props: ['communityInfoMap','projectName'],
  mounted () { },
  computed: {
    optionSingleHeightTime () {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 200, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1200, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {},
  onCountyRowClick (row) {
    // console.log(row);
  },
};
</script>

<style lang="scss" scoped>
#rightCounty {
  padding: 0.2rem;
  // height: 7rem;
  // height: 8.125rem;
  height: 100%;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    // height: 6.6rem;
    height: 100%;
    border-radius: 0.125rem;
    position: relative;
  }
  .text {
    color: #c3cbde;
  }
}
.mainBox {
  padding: 0px 20px;
}
.seamless-warp {
  width: 100%;
  height: 6rem;
  overflow: hidden;
}
.item {
  padding-top: 20px;
  padding-bottom: 30px;
  border-top: 2px dotted #fff;
  overflow: hidden;
  .item_div{
    font-size: .3rem;
    color: orange;
    line-height: .3rem;
    border: 1px solid #ccc;
  }
  li {
    width: 100%;
    float: left;
    margin-top: 20px;
    h2 {
      color: #2ae9f2;
      font-size: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #fff;
    }
    h4 {
      font-size: 16px;
      font-weight: normal;
      float: left;
      width: 100%;
      margin-top: 10px;
      // color: #1895e5;
      span {
        float: right;
        i {
          font-style: normal;
          font-size: 18px;
          font-weight: bold;
          color: #2ae9f2;
        }
      }
    }
  }
}
.center {
   position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.4rem;
  // text-align: center;
  // line-height: 100%;
  // font-size: 40px;
  // font-family: "Courier New", Courier, monospace;
}
</style>
