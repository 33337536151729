
export const mapOption = {
    basicOption: {
        tooltip: {
            trigger: 'item',
            // position:[10,10],
            backgroundColor : 'rgba(50,50,50,0.9)',
            textStyle:{
                color: '#fff',
            },
            formatter: function (params) { // 鼠标划过提示语
                let pna = params.name
                return pna;
            }
        },
    //左侧小导航图标
    visualMap: {
        show: false, // 不显示地图左上角图标 
        pieces: [
            { min: 0, max: 1, label: '区块', color: 'rgba(0, 0, 0, 0.3)' }, //区块的背景色
        ],
        textStyle: {
            color: '#fff',
        },
     },
    },
    geo: {
        map: 'china',
        show: true,
        type: 'scatter',
        itemStyle: {
            areaColor: '#323c48',
            borderColor: '#111'
        },
        emphasis: {
            itemStyle: {
                areaColor: '#2a333d'
            },
            label: {
                show: false
            }
        }
    },
    // seriesOption: {
    //     type: 'map',
    //     coordinateSystem:'geo',
    //     // roam: false,
    //     nameMap: {
    //         'china': '西湖区'
    //     },
    //     label: {
    //         normal: {
    //             show: true,
    //             textStyle: {
    //                 color: '#ccc', // 地图文字
    //             }
    //         },
    //         emphasis: {
    //             show: true,
    //             textStyle: {
    //                 color: '#fff',// 鼠标划过地图文字
    //             }
    //         }
    //     },
    //     itemStyle: {
    //         normal: {
    //           borderWidth: 1, //边际线大小
    //           borderColor: "#1170D4", //边界线颜色
    //           // areaColor: "#09295b", //默认区域颜色 
    //           label: {
    //             show: true,
    //             textStyle: {
    //               color: "rgb(249, 249, 249)",
    //             }
    //           },
    //         },
    //         emphasis: {
    //           borderWidth: 2, //边际线大小
    //           // 普通图表的高亮颜色
    //           color: "red",
    //          // borderColor: "#9AE09E", //边界线颜色
    //           // 地图区域的高亮颜色
    //           areaColor: "rgb(3, 25, 81)",
    //         },
    //       },
    // }
}
