<template>
  <div style="height:100%">
    <div id="blue-lefttop">
      <div class="bg-color-black">
        <div class="text">
          蓝领公寓出租情况
        </div>
        <div id="parent" style="height: 96%;">
          <div class="desc">
            <div class="item"><span class="">项目</span><span class="num">{{totalData.communityCount | changeNum}}</span><span class="unit">个</span>
            </div>
            <div class="item"><span class="">总房间数</span><span class="num">{{totalData.summaryHouseCount | changeNum}}</span><span class="unit">间</span>
            </div>
            <div class="item"><span class="">已出租间数</span><span class="num">{{totalData.leasedHouseCount | changeNum}}</span><span class="unit">间</span>
            </div>
          </div>
          <div class="histogram-info">
            <div id="blueDesc"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="leftLoops">
      <div class="bg-color-black">
        <div class="text">
          蓝领项目占比
        </div>
        <div class="histogram-info">
          <Company :company='company1' />
          <div id="blueChart">
          </div>
          <p class="empty" v-if='emptyText'>暂无信息</p>
        </div>
        <div class="text">
          出租情况
        </div>
        <div class="histogram-info">
          <Company :company='company' />
          <div id="myChartsLine">
          </div>
        </div>
        <!-- <div class="text">
          蓝领项目占比
        </div>
        <div class="histogram-info">
          <div id="blueChart">
          </div>
          <p class="empty" v-if='emptyText'>暂无信息</p>
        </div> -->
      </div>
    </div>
  </div>

</template>

<script>
import Company from '../Company'
import blueApi from '@/xhr/blue'
import { changeNum } from '@/filters/thousand'
export default {
  name: "blue-lefttop",
  filters: {
    changeNum
  },
  data () {
    return {
      company: '间/月',
      company1: '间',
      emptyText: false,
      index: true,
      data: [],
      totalData: {},
      timer: null,
    };
  },
  components: {
    Company
  },
  created () {
    this.getData()
  },
  mounted () {
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.getData()
      }, 0)
    }, 60000)
    // this.draw();
    // this.drawRate();
    // this.drawLine();
  },
  destroyed () {
    //  销毁定时器
    window.clearInterval(this.timer)
  },
  methods: {
    getData () {
      blueApi.getLeftData().then(res => {
        if (res.data.code === '200') {
          if (res.data.data) {
            this.totalData = res.data.data
            // 处理折线图数据 
            let lastArr = [] //去年数据
            let lastMonth = []
            let recentArr = [] //今年数据
            let recentMonth = []
            this.totalData.lastYearInfo.forEach(el => {
              lastArr.push(el.value)
              lastMonth.push(el.month)
            })
            this.totalData.recentHalfYearInfo.forEach(el => {
              recentArr.push(el.value)
              recentMonth.push(el.month)
            })
            // 处理饼图数据 从大到小排序 取前十个展示
            this.totalData.communityArea.sort((a, b) => {
              return b.value - a.value
            })
            if (this.totalData.communityArea.length === 0) {
              this.emptyText = true
            } else {
              this.emptyText = false
            }
            let maxArr = []
            if (this.totalData.communityArea.length > 5) {
              // splice 方法改变原数组=> communityArea
              maxArr = this.totalData.communityArea.splice(0, 5)
              // 截取前十位 剩余数据相加为其他
              let otherNum = 0;
              this.totalData.communityArea.forEach(el => {
                otherNum = otherNum + el.value
              })
              maxArr.push({ value: otherNum, name: '其他' })
            } else {
              maxArr = this.totalData.communityArea
            }
            // 绘制环形图
            this.$nextTick(() => {
              this.draw(this.totalData.leasedHouseCount, this.totalData.summaryHouseCount)
              // 绘制双折线图 去年数据  今年年数据
              this.drawLine(lastMonth, lastArr)
              // 绘制饼图
              this.drawRate(maxArr)
            })
          }

        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    draw (val, total) {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("blueDesc")
      );
      // 绘制环形 7CCFD3 29D9E8 299DC0 285283
      var option = {
        label: {
          color: '#fff'
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "65%"],
            center: ["50%", "40%"],
            data: [
              {
                name: "出租率",
                value: val,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#356eff", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#9bfcaf", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
                label: {
                  show: true, //单独显示该数据项
                  formatter: function (obj) {
                    return obj.name + '\n' + obj.percent.toFixed(0) + '%'
                  },
                  color: "#49c3e3", // 100% 处的颜色
                  fontSize: '20',
                  fontWeight: 'bold'
                },
              },
              {
                value: total - val,
                itemStyle: {
                  color: "#ccc",
                },
                label: {
                  emphasis: {
                    show: false,
                  },
                },
              },
            ],
            itemStyle: {
              normal: {},
            },
            label: {
              normal: {
                //默认不显示数据
                show: false,
                position: "center",
              },
              color: "#fff",
            },
          },
        ],
      };
      myChartDrawer.setOption(option);
    },
    drawRate (val) {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("blueChart")
      );
      var option = {
        label: {
          color: '#fff'
        },
        title: {

        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}间 ({d}%)'
        },
        series: [
          // {
          //   name: '租赁概况',
          //   type: 'pie',
          //   radius: ['45%', '70%'],// 饼图大小
          //   center: ['50%', '60%'],// 饼图位置
          //   data: val,
          //   itemStyle: {
          //     normal: {
          //       color: function (params) {
          //         var colorlist = ['#4FE0B6', '#44B079', '#C1BF52', '#6CAE40', '#1989EA', '#156AE4', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
          //         return colorlist[params.dataIndex];
          //       },
          //     }
          //   },
          //   emphasis: {
          //     itemStyle: {
          //       shadowBlur: 10,
          //       shadowOffsetX: 0,
          //       shadowColor: 'rgba(0, 0, 0, 0.5)'
          //     }
          //   },
          //   label: {
          //     normal: {
          //       formatter: '{b}\n{d}%',
          //       textStyle: {
          //         fontSize: 12, //指示文字
          //       }
          //     }
          //   }
          // }
          {
            data: val,
            name: "",
            type: "pie",
            radius: ['45%', '70%'],// 饼图大小
            center: ['50%', '60%'],// 饼图位置
            itemStyle: {
              color: function (params) {
                // var colorlist = ['#DE2425', '#2CF9DC', '#178BF9', '#FDDC55', '#F47D7A', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                var colorlist = ['#4FE0B6', '#44B079', '#C1BF52', '#6CAE40', '#1989EA', '#156AE4', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                // var colorlist = ['#CBD435', '#f6eeda', '#f29a8b', '#e06641', '#F47D7A']
                return colorlist[params.dataIndex];
              },
            },
            height: '100%',
            left: 'center',
            width: '100%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            label: {
              color: '#fff',
              alignTo: 'edge',
              formatter: '{name|{b}}\n{c} ({d}%) ',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 20,
              // color: 'red',
              rich: {
                time: {
                  fontSize: 14,
                  color: '#fff'
                }
              }
            },
            labelLine: {
              length: 20,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout (params) {
              var isLeft = params.labelRect.x < myChartDrawer.getWidth() / 2;
              var points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;

              return {
                labelLinePoints: points
              };
            },
          },
        ]
      };
      myChartDrawer.setOption(option);
    },
    // 半年出租情况
    drawLine (month, current) {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("myChartsLine")
      );
      var option = {
        label: {
          color: '#fff'
        },
        title: {
          // text: '堆叠区域图'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        // legend: {
        //   textStyle: {
        //     color: '#fff'
        //   },
        //   // data: ['出租间数', '房源总间数', '出租率']
        // },
        grid: {
          // left: '1%',
          // right: '4%',
          bottom: '6%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: month,
            axisLabel: {
              rotate: 20,
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 800,
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            },

          },

        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {

              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            }, //
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 800,
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            },
          }
        ],
        series: [
          {
            name: '出租情况',
            type: 'line',
            // stack: '总量',
            data: current,
            smooth: true,// 线条平滑
            symbol: 'circle',// 折线点实心
            symbolSize: 6,// 折线点大小
            // 图例
            itemStyle: {
              borderWidth: 2,
              color: 'rgb(24,69,229)',
            },
            // 线条颜色
            lineStyle: {
              color: 'rgb(24,69,229)'
            },
            label: {
              show: true,
              position: 'top'
            },
            // 填充区域颜色
            areaStyle: {
              color: {
                type: 'linear',
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgb(24,69,229)' // 0% 处的颜色
                }, {
                  offset: 0.4, color: 'rgb(24,69,229,.7)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgb(24,69,229,0)' // 100% 处的颜色
                }],
                global: false
              }
            }
          },
        ]
      };
      myChartDrawer.setOption(option);
    },

  },
};
</script>

<style lang="scss" scoped>
#blue-lefttop {
  padding: 0.18rem;
  height: 30%;
  min-width: 7rem;
  border-radius: 0.0625rem;
  width: 100%;
  overflow: hidden;
  .histogram-info {
    position: relative;
    width: 50%;
    height: 100%;
  }
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
    overflow: hidden;
    width: 100%;
    padding: 0.15rem;
    .text {
      font-size: 0.25rem;
      font-weight: bold;
      color: #fff;
      width: 100%;
      text-align: left;
      border-bottom: 0.03rem solid #ffffff;
      padding-bottom: 0.12rem;
    }
  }

  #parent::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  #parent {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    // overflow-y: auto;
    display: flex;
  }
}
.desc {
  width: 50%;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  font-size: 0.25rem;
  .item {
    width: 100%;
    .unit {
      font-size: 0.2rem;
      margin-left: 0.1rem;
    }
    .num {
      font-size: 0.3rem;
      font-weight: bold;
      margin-left: 0.1rem;
    }
  }
}

#blueDesc {
  width: 100%;
  height: 100%;
  // min-height: 220px;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}
::v-deep#leftLoops {
  padding: 0 0.2rem 0.2rem 0.2rem;
  height: 70%;
  border-radius: 0.0625rem;
  overflow: hidden;
  .histogram-info {
    height: 50%;
    position: relative;
    .empty {
      position: absolute;
      top: 40%;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 0.4rem;
    }
  }
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
    padding: 0.15rem;
    .text {
      font-size: 0.25rem;
      font-weight: bold;
      color: #fff;
      width: 100%;
      text-align: left;
      border-bottom: 0.03rem solid #ffffff;
      padding-bottom: 0.12rem;
    }
  }
  .d-flex pt-2 pl-2 {
    height: 5%;
  }
  .text {
    color: #c3cbde;
  }
  .color0 {
    color: #ff5722;
  }

  .color1 {
    color: #409eff;
  }
}

#myChartsLine,
#blueChart {
  width: 100%;
  height: 90%;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}
</style>
