<template>
  <div id="leftShop">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <template>
            <span class="fs-xl text mx-2">商铺最近一年出租情况</span>
          </template>
        </div>
      </div>
      <div class="histogram-info">
        <Company :company='company' />
        <div id="rentShop"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Company from '../Company'
export default {
  name: "rightHouse",
  data () {
    return {
      company: '万/平方',
      myChartDrawer: null,
    }
  },
  components: {
    Company
  },
  mounted () {
    // let _this = this
    // window.addEventListener('resize', function () {
    //   if (_this.resizeTimer) clearTimeout(_this.resizeTimer);
    //   _this.resizeTimer = setTimeout(function () {
    //     _this.myChartDrawer.resize();
    //   }, 100)
    // })
  },
  methods: {
    draw (value, month) {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("rentShop")
      );
      this.myChartDrawer = myChartDrawer
      var option = {
        title: {
          // text: '堆叠区域图'
        },
        label: {
          color: '#fff'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          formatter: function (params) {
            var result = params[0].name;
            params.forEach(function (item) {
              if (item.data) {
                result += '<br/>' + item.data + '平方';
              }
            });
            return result;
          }
        },
        legend: {
          textStyle: {
            color: '#fff'
          },
          // data: ['出租间数', '房源总间数', '出租率']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: month,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 800,
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            },

          },

        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            }, //
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 800,
                fontSize: 14,
              },
              formatter: function (value, index) {
                if (value != 0)
                  return (value / 10000).toFixed(1);
                else
                  return 0;
              }
            },
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            },
          }
        ],
        series: [
          {
            // name: '2015年',
            // name: twoYeaName,
            type: 'line',
            // stack: '总量',
            areaStyle: {},
            // data: [1000, 800, 800, 700, 700, 800, 800, 1000, 1100, 1300, 1700, 1900],
            data: value,
            smooth: true,// 线条平滑
            symbol: 'circle',// 折线点实心
            symbolSize: 6,// 折线点大小
            // 图例
            itemStyle: {
              borderWidth: 2,
              color: 'rgb(24,69,229)',
            },
            // 线条颜色
            lineStyle: {
              color: 'rgb(24,69,229)'
            },
            label: {
              show: true,
              position: 'top',
              formatter: function (params) { // 鼠标划过提示语
                let pna = ((params.value) / 10000).toFixed(2)
                return pna;
              },
            },
            // 填充区域颜色
            areaStyle: {
              color: {
                type: 'linear',
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgb(24,69,229)' // 0% 处的颜色
                }, {
                  offset: 0.4, color: 'rgb(24,69,229,.7)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgb(24,69,229,0)' // 100% 处的颜色
                }],
                global: false
              }
            }
          },

        ]
      };

      myChartDrawer.setOption(option);
    },
  }
}
</script>

<style lang="scss" scoped>
#leftShop {
  padding: 0.2rem;
  height: 100%;
  //   min-width: 7rem;
  border-radius: 0.0625rem;

  .bg-color-black {
    width: 100%;
    height: 100%;
    border-radius: 0.125rem;
  }
  .text {
    // color: #c3cbde;
    font-size: 0.25rem;
    font-weight: bold;
    color: #fff;
  }
}
.d-flex.pt-2 {
  height: 6%;
}
.histogram-info {
  position: relative;
  height: 94%;
  width: 100%;
}
#rentShop {
  width: 90%;
  height: 100%;
  min-height: 220px;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}
</style>