<template>
  <div id="bluerightTop">
    <swiper class="swiper" v-if="this.infos.length">
      <swiper-slide class="swiper-slide" v-for="(item, index) in infos" :key="index">
        <div class="bg-color-black">
          <div class="text">
            <p class="project">{{projectName}}商铺项目</p>
            <p class="address">{{projectName}}{{item.communityName}}</p>
          </div>
          <div class="histogram-info">
            <div class="item">
              <div class="left_chart">
                <div class="areaChart" :id="'areaChart' + index" :ref="'areaChart' + index"></div>
              </div>

              <div class="right_context">
                <div class="list">
                  <span class="type">总面积</span><span class="num">{{ item.summaryArea }}</span><span class="unit">平方</span>
                </div>
                <div class="list">
                  <span class="type">已出租</span><span class="num">{{ item.leasedArea }}</span><span class="unit">平方</span>
                </div>
              </div>
            </div>
            <div class="chuzu">
              <div class="text">出租情况</div>
              <div class="histogram-info">
                <Company :company='company' />
                <div class="rentChart" :id="'rentChart' + index" :ref="'rentChart' + index"></div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div v-if="!this.infos ||this.infos.length ===0" class="center">暂无信息</div>
  </div>
</template>

<script>
import Company from '../Company'
export default {
  name: "rightProvince",
  data () {
    return {
      company: '万/平方/月',
      listData: [
        {
          title: "无缝滚动第九行无缝滚动第九行",
          date: "2017-12-16",
        },
      ],
      slide: [1, 2],
    };
  },
  props: ["infos", 'projectName'],
  components: {
    Company
    //组件
    // vueSeamlessScroll,
    // Swiper,
    // SwiperSlide
  },
  computed: {
    optionSingleHeightTime () {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: null, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 200, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1200, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  watch: {
    infos (val) {
      if (val.length > 0) {
        setTimeout(() => {
          this.infos.forEach((item, index) => {
            this.$nextTick(() => {
              this.drawArea(index, item.summaryArea, item.leasedArea);
            })
            let arr = [];
            let month = [];
            item.halfYearInfo.forEach((item, index) => {
              arr.push(item.value)
              month.push(item.month)
            })
            this.$nextTick(() => {
              this.drawLast(index, arr, month);
            })
          });
        }, 200);

      }
    }
  },
  mounted () {

  },
  destroyed () { },
  methods: {
    drawArea (ind, val, total) {
      // 实例化echarts对象
      let name = "areaChart" + ind;
      let refrehousing = this.$refs[name][0]
      if (refrehousing) {

        let myChartDrawer = this.$echarts.init(refrehousing);
        var option = {
          label: {
            color: '#fff'
          },
          series: [
            {
              type: "pie",
              radius: ["60%", "75%"],
              center: ["50%", "50%"],
              data: [
                {
                  name: "出租率",
                  value: total,
                  itemStyle: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 1,
                      x2: 0,
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#356eff", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#9bfcaf", // 100% 处的颜色
                        },
                      ],

                      global: false, // 缺省为 false
                    },
                  },
                  label: {
                    show: true, //单独显示该数据项
                    formatter: "面积出租率" + "\n" + "{d}%",
                    color: "#49c3e3", // 100% 处的颜色
                    fontSize: "20",
                    fontWeight: "bold",
                  },
                },
                {
                  value: val - total,
                  itemStyle: {
                    color: "#ccc",
                  },
                  label: {
                    emphasis: {
                      show: false,
                    },
                  },
                },
              ],
              itemStyle: {
                normal: {},
              },
              label: {
                normal: {
                  //默认不显示数据
                  show: false,
                  position: "center",
                },
                color: "#fff",
              },
            },
          ],
        };
        myChartDrawer.setOption(option);

      } else {

      }
    },
    drawLast (ind, arr, month) {
      let name = "rentChart" + ind;
      //  let name = 'Rehousing_tate' + ind
      let refrehousing = this.$refs[name][0]
      if (refrehousing) {
        // 实例化echarts对象
        let myChartDrawer = this.$echarts.init(
          // document.getElementById(name)
          refrehousing
        );
        var option = {
          label: {
            color: '#fff'
          },
          title: {},
          grid: {
            top: 0,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
            formatter: function (params) {
              var result = params[0].name;
              params.forEach(function (item) {
                if (item.data) {
                  result += '<br/>' + item.data + '平方';
                }
              });
              return result;
            }
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "4%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              // data: ["7月", "8月", "9月", "10月", "11月", "12月"],
              data: month,
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#FFFFFF",
                  fontWeight: 800,
                  fontSize: 12,
                },
              },
              axisLine: {
                lineStyle: {
                  type: "solid",
                  color: "#1C4274",
                  width: "1",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              splitLine: {
                lineStyle: {
                  type: "solid",
                  color: "#1C4274",
                  width: "1",
                },
              }, //
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#FFFFFF",
                  fontWeight: 800,
                  fontSize: 14,
                },
                formatter: function (value, index) {
                  if (value != 0)
                    return (value / 10000).toFixed(2);
                  else
                    return 0;
                }
              },
              axisLine: {
                lineStyle: {
                  type: "solid",
                  color: "#1C4274",
                  width: "1",
                },
              },
            },
          ],
          series: [
            {
              // name: "2018年",
              type: "line",
              data: arr,
              smooth: true, // 线条平滑
              symbol: "circle", // 折线点实心
              symbolSize: 6, // 折线点大小
              // 图例
              itemStyle: {
                borderWidth: 2,
                color: "rgb(24,69,229)",
              },
              label: {
                show: true,
                position: 'top',
                formatter: function (params) { // 鼠标划过提示语
                  let pna = ((params.value) / 10000).toFixed(2)
                  return pna;
                },
              },
              // 线条颜色
              lineStyle: {
                color: "rgb(24,69,229)",
              },
              // 填充区域颜色
              areaStyle: {
                color: {
                  type: "linear",
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgb(24,69,229)", // 0% 处的颜色
                    },
                    {
                      offset: 0.4,
                      color: "rgb(24,69,229,.7)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgb(24,69,229,0)", // 100% 处的颜色
                    },
                  ],
                  global: false,
                },
              },
            },
          ],
        };
        myChartDrawer.setOption(option);

      } else {

      }
    },
  },
};
</script>

<style lang="scss" scoped>
#bluerightTop {
  padding: 0.2rem;
  // height: 5.125rem;
  // height: 4rem;
  height: 100%;
  min-width: 7rem;
  border-radius: 0.0625rem;
  position: relative;

  .bg-color-black {
    // height: 4.8125rem;
    padding: 0.15rem;
    width: 100%;
    height: 100%;
    border-radius: 0.125rem;
  }
  .text {
    font-size: 0.25rem;
    font-weight: bold;
    color: #fff;
    width: 100%;
    text-align: left;
    border-bottom: 0.03rem solid #ffffff;
    padding-bottom: 0.12rem;
    .project {
      margin-bottom: 0.18rem;
    }
    .address {
      text-align: right;
    }
  }
}
.d-flex.pt-2 {
  height: 6%;
}
.histogram-info {
  position: relative;
  height: 90%;
  width: 100%;
  display: flex;
  // background-color: #325832;
  flex-direction: column;
  .item {
    flex: 1;
    //  background-color: #ccc;
    display: flex;
    .left_chart {
      flex: 1;
      display: flex;
      .areaChart {
        flex: 1;
        width: 100%;
      }
    }
    .right_context {
      flex: 1;
      display: flex;
      height: 100%;
      flex-direction: column;
      // flex-wrap: wrap;
      // align-items: center;
      justify-content: center;
      .list {
        //    justify-content: space-around;
        // align-content: center;
        flex: 1;
        color: #fff;
        font-size: 0.25rem;
        // margin-bottom: 0.18rem;
        line-height: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        .unit {
          font-size: 0.2rem;
          margin-left: 0.1rem;
          line-height: 100%;
        }
        .num {
          font-size: 0.3rem;
          font-weight: bold;
          margin-left: 0.1rem;
          line-height: 100%;
        }
      }
    }
  }
  .chuzu {
    flex: 1;
    height: 100%;
    // background-color: #fff;
    .text {
      font-size: 0.25rem;
      font-weight: bold;
      color: #fff;
      width: 100%;
      text-align: left;
      border-bottom: 0.03rem solid #ffffff;
      padding-bottom: 0.12rem;
      .num {
        font-size: 0.3rem;
        font-weight: bold;
        margin: 0 0.1rem;
      }
    }
  }
}
.areaChart,
.rentChart {
  width: 90%;
  height: 100%;
  // min-height: 220px;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
  margin-bottom: 0.2rem;
}
.swiper-slide {
  width: 100%;
  height: 100%;
  // line-height: 500px;
  // font-size: 50px;
  // text-align: center;
  // background-color: rosybrown;
}
.swiper {
  height: 100%;
}
.center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.6rem;
  // text-align: center;
  // line-height: 100%;
  // height: 100%;
  // margin-top: 10%;
  // font-size: 40px;
  // font-family: "Courier New", Courier, monospace;
}
</style>
