<template>
  <div id="rightProvince">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-pie"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">{{projectName || ''}}安置房使用情况</span>
          <!-- <dv-decoration-3 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" /> -->
        </div>
      </div>
      <div class="histogram-info" v-if="sumItems.length">
        <Company :company='company' />
        <div id="myChartsRight" ref="chart"></div>
      </div>
      <div class="myChartsRight" v-if="!sumItems.length">暂无信息</div>
    </div>
  </div>
</template>

<script>
import Company from '../Company'
export default {
  data () {
    return {
      company: '套',
    };
  },
  props: ["suminfo", "sumItems", 'projectName'],
  mounted () {

  },
  components: {
    Company
  },
  methods: {
    drawRate1 (val) {
      // 实例化echarts对象
      let bar_dv = this.$refs.chart;
      if (bar_dv) {
        let myChartDrawer = this.$echarts.init(
          bar_dv
        );
        var option = {
          title: {},
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c}套 ({d}%)",
          },
          label: {
            color: '#fff'
          },
          series: [
            // {
            //   name: "租赁概况",
            //   type: "pie",
            //   radius: "70%", // 饼图大小
            //   center: ["50%", "60%"], // 饼图位置
            //   data: val,
            //    itemStyle: {
            //     color: function (params) {
            //       var colorlist = ['#4FE0B6', '#15CDCA', '#42A5F5', '#C7CF77', '#3E60C1', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
            //       return colorlist[params.dataIndex];
            //     },
            //   },
            //   emphasis: {
            //     itemStyle: {
            //       shadowBlur: 10,
            //       shadowOffsetX: 0,
            //       shadowColor: "rgba(0, 0, 0, 0.5)",
            //     },
            //   },
            //   label: {
            //     normal: {
            //       formatter: "{b}\n{d}%",
            //       textStyle: {
            //         fontSize: 15, //指示文字
            //       },
            //     },
            //   },
            // },
            {
              data: val,
              name: "",
              type: "pie",
              radius: '50%',
              center: ["50%", "60%"],
              itemStyle: {
                color: function (params) {
                  // var colorlist = ['#DE2425', '#2CF9DC', '#178BF9', '#FDDC55', '#F47D7A', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                  var colorlist = ['#1989EA', '#156AE4', '#C1BF52', '#C49741', '#4FE0B6', '#44B079', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                  // var colorlist = ['#CBD435', '#f6eeda', '#f29a8b', '#e06641', '#F47D7A']
                  return colorlist[params.dataIndex];
                },
              },
              height: '100%',
              left: 'center',
              width: '90%',
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.2)",
                },
              },
              label: {
                color: '#fff',
                alignTo: 'edge',
                formatter: '{name|{b}}\n{time|{c} ({d}%)}',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 20,
                // color: 'red',
                rich: {
                  time: {
                    fontSize: 14,
                    color: '#fff'
                  }
                }
              },
              labelLine: {
                length: 30,
                length2: 0,
                maxSurfaceAngle: 80
              },
              labelLayout (params) {
                var isLeft = params.labelRect.x < myChartDrawer.getWidth() / 2;
                var points = params.labelLinePoints;
                // Update the end point.
                points[2][0] = isLeft
                  ? params.labelRect.x
                  : params.labelRect.x + params.labelRect.width;

                return {
                  labelLinePoints: points
                };
              },
            },
          ],
        };
        myChartDrawer.setOption(option);
      } else {

      }
    },
  },
};
</script>

<style lang="scss" scoped>
#rightProvince {
  padding: 0.2rem;
  // height: 5.125rem;
  // height: 4rem;
  height: 100%;
  min-width: 7rem;
  border-radius: 0.0625rem;

  .bg-color-black {
    // padding: 0.3rem;
    // height: 4.8125rem;
    width: 100%;
    height: 100%;
    border-radius: 0.125rem;
    display: flex;
    flex-direction: column;
    .d-flex pt-2 pl-2 {
      height: 5%;
    }
    .text {
      // color: #c3cbde;
      font-size: 0.25rem;
      font-weight: bold;
      color: #fff;
    }

    .color0 {
      color: #ff5722;
    }

    .color1 {
      color: #409eff;
    }
  }
}
.text {
  font-size: 0.23rem;
  font-weight: bold;
  color: #fff;
  width: 100%;
  text-align: left;
  // border-bottom: 0.03rem solid #ffffff;
  // padding-bottom: 0.12rem;
}
.d-flex.pt-2 {
  height: 6%;
}
.histogram-info {
  height: 94%;
  width: 100%;
  display: flex;
  //  background-color: #fff;
}
#myChartsRight {
  width: 100%;
  height: 100%;
  // min-height: 220px;
  clear: both;
  box-sizing: border-box;
  // margin: 0px auto;
}
.myChartsRight {
  // display: flex;
  // position: absolute;
  // width: 100%;
  // //   clear: both;
  // align-items: center;
  // justify-content: center;
  // //   box-sizing: border-box;
  // text-align: center;
  // line-height: 100%;
  // font-size: 0.5rem;
  // font-weight: 400;
  // color: #fff;

  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.4rem;
}
.itemone {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  .xinxi {
    height: 100%;
    font-size: 0.15rem;
    font-weight: bold;
    flex: 1;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    // background: rgb(167, 173, 113);
    p {
      font-size: 0.15rem;
      font-weight: bold;
      line-height: 0.15rem;
      // text-align: left;
      // width: 100%;
      z-index: 0px;
      margin-left: -1rem;
      padding-bottom: 0.1rem;
    }
    h5 {
      font-size: 0.3rem;
      line-height: 0.3rem;
      span {
        font-size: 0.15rem;
      }
    }
  }
}
.number_car {
  flex: 1;
  // background-color: #443422;
  display: flex;
  flex-direction: column;
  //  align-items: center;

  .p1 {
    padding-bottom: 0.2rem;
    flex: 1;
    font-size: 0.4rem;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    // line-height: .3rem;
    // justify-content: center;
  }
  .p2 {
    padding-top: 0.2rem;
    flex: 1;
    font-size: 0.4rem;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // line-height: .3rem;
    // justify-content: center;
  }
}
</style>
