<template>
  <div id="rightCounty">
    <div class="bg-color-black">
      <div class="mainBox">

        <vue-seamless-scroll v-if="this.parkInfos !== 'null' && this.parkInfos && this.parkInfos.length >0" :data="this.parkInfos" :class-option="optionSingleHeightTime" class="seamless-warp">
          <ul class="item">
            <li v-for=" item in parkInfos" :key="item.id">
              <h2>{{item.parkName}}</h2>
              <h4>当前停车场地址<span>{{item.address}}</span></h4>
              <h4>投入时间<span>{{item.expectUseTime}}</span></h4>
              <h4>经营管理单位<span>{{item.manageDepart}}</span></h4>
              <h4>建设信息<span>{{item.buildDepart}}</span></h4>
              <h4>性质<span>{{item.parkType | parkType}}</span></h4>
              <h4>状态<span>{{item.parkStatus |parkStatus}}</span></h4>
              <h4>面积<span>{{item.buildArea}}平方</span></h4>
            </li>
          </ul>
        </vue-seamless-scroll>
        <div v-if="!this.parkInfos ||this.parkInfos.length < 1" class="center">暂无信息</div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { parkType, parkStatus } from '@/filters/park'
export default {
  components: {
    //组件
    vueSeamlessScroll,
  },
  name: "rightCounty",
  data () {
    return {
      listData: [
        {
          title: "无缝滚动第九行无缝滚动第九行",
          date: "2017-12-16",
        },

      ],
    };
  },
  filters: {
    parkType,
    parkStatus
  },
  props: ['parkInfos'],
  mounted () {
    // this.parkInfos = null
   },
  computed: {
    optionSingleHeightTime () {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1200, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {},
  onCountyRowClick (row) {
    // console.log(row);
  },
};
</script>

<style lang="scss" scoped>
#rightCounty {
  padding: 0.2rem;
  height: 100%;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  overflow: hidden;
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
}
.mainBox {
  padding: 20px;
  position: relative;
  height: 100%;
  width: 100%;
}
.seamless-warp {
  width: 100%;
  height: 6rem;
  overflow: hidden;
}
.item {
  padding-top: 20px;
  padding-bottom: 30px;
  border-top: 2px dotted #fff;
  overflow: hidden;
  li {
    width: 100%;
    float: left;
    margin-top: 20px;
    h2 {
      color: #2ae9f2;
      font-size: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #fff;
    }
    h4 {
      font-size: 16px;
      line-height: 18px;
      font-weight: normal;
      float: left;
      width: 100%;
      margin-top: 10px;
      // color: #1895e5;
      span {
        float: right;
        white-space:normal;
        word-break:break-all;
        overflow: hidden;
        i {
          font-style: normal;
          font-size: 18px;
          font-weight: bold;
          color: #2ae9f2;
        }
      }
    }
  }
}
.center {
   position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0.6rem;
  // text-align: center;
  // line-height: 100%;
  // font-size: 40px;
  // font-family: "Courier New", Courier, monospace;
}
</style>
