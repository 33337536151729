<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="dataweather">
        <div>
          {{ dataWeather.city }}
          {{ dataWeather.weathertemp }}
          <img :src="dataWeather.icontemp" alt="" />
          {{ dataWeather.peraturetemp }}
        </div>
      </div>
      <div class="datatime">
        <span>
          {{ dataTime.dateYear }} {{ dataTime.dateWeek }} {{ dataTime.dateDay }}
        </span>
      </div>
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <top-header />
        <div class="body-box">
          <div class="content-box info-four" style="width: 100%;display: block">
            <div style="height:100%;width: 100%;display: block">
              <dv-border-box-10 style="height:100%;width: 100%">
                <center-form @tab='tabChange' />
              </dv-border-box-10>
            </div>
          </div>

          <div class="content-box special">
            <div style="height: 100%; width: 30%" v-if="tabName === 'INDEX'">
              <dv-border-box-12 style="height: 60%">
                <!-- <div style="height: 60%"> -->
                <left-level :areaData="areaData" />
                <!-- </div> -->
              </dv-border-box-12>
              <!-- <div style="height: 40%"> -->
              <!-- </div> -->
              <dv-border-box-13 style="height: 40%">
                <left-loops />
              </dv-border-box-13>
            </div>
            <div style="height:100%;width:30%" v-if="tabName==='PARK'">
              <park-lefttop :areaData="areaData" />
            </div>
            <div style="height: 100%; width: 30%" v-if="tabName === 'BLUE'">
              <dv-border-box-12 style="height: 100%">
                <!-- <div style="height: 100%"> -->
                <blue-lefttop />
                <!-- </div> -->
              </dv-border-box-12>
            </div>
            <div style="height: 100%; width: 30%" v-if="tabName === 'SHOP'">
              <dv-border-box-12 style="height: 100%">
                <!-- <div style="height: 100%"> -->
                <shoplefttop />
                <!-- </div> -->
              </dv-border-box-12>
            </div>
            <div style="height: 100%; width: 30%" v-if="tabName === 'ARRANGE'">
              <div style="height: 100%">
                <arrange-lefttop :areaData="areaData" />
              </div>
            </div>

            <div style="height:100%;width:100%" v-if="tabName==='RENT'" class="rent">
              <div class="rentLeft">
                <dv-border-box-13 style="height:45%;width:100%">
                  <!-- <div style="height:50%;width:100%"> -->
                  <rent-table :infoData='rentData.curLeaseInfo' />
                  <!-- </div> -->
                </dv-border-box-13>
                <dv-border-box-13 class="double" style="height:55%;width:100%;">
                  <!-- <div  class="double" style="height:50%;width:100%;"> -->
                  <rent-shop ref="rentShop" style="width:100%" />
                  <!-- </div> -->
                  <!--   <rent-other ref="rentOther" style="width:50%" /> -->
                </dv-border-box-13>
              </div>
              <div class="rentRight">
                <dv-border-box-13 style="height:32.5%;width:100%">
                  <!-- <div  style="height:32.5%;width:100%"> -->
                  <rent-pies ref="rentPies" />
                  <!-- </div> -->
                </dv-border-box-13>
                <dv-border-box-13 style="height:32.5%;width:100%">
                  <!-- <div style="height:32.5%;width:100%"> -->
                  <rent-house ref="rentHouse" />
                  <!-- </div> -->
                </dv-border-box-13>
                <dv-border-box-13 style="height:35%;width:100%">

                  <!-- <div  style="height:35%;width:100%"> -->
                  <rent-office ref="rentOffice" />
                  <!-- </div> -->
                </dv-border-box-13>
              </div>
            </div>
            <div class="china" :class="{width1:heiwidth}" v-if="tabName !== 'RENT'">
              <!-- <dv-border-box-11 :title="chinaMapTitle" style="height: 100%"> -->
              <!--                                    echarts地图-->
              <div class="chinaslice">
                <china ref="china" v-on:adress="watchAdress" v-on:adress1="watchAdress1" @map-change=" mapClick" :currentTab='tabName' @back-change="backClick" v-if="isTest" key='chinamap' />
              </div>
              <!--                                    高德地图-->
              <!--                                <china-gaode/>-->
              <!-- </dv-border-box-11> -->
            </div>
            <!-- <div style="height: 100%; width: 30%" v-if="tabName === 'SHOP'">
              <div style="height: 100%">
                <shopright :areaData="areaData" />
              </div>
            </div> -->
            <div style="height: 100%; width: 30%" v-if="tabName === 'INDEX'">
              <!-- <div style="height: 30%" >
                 <right-province :regionLeaseInfoList="regionLeaseInfoList" :projectName="projectName" ref="inedxadd" />
              </div>
              <div  style="height: 70%">
                  <right-county :communityInfoMap="communityInfoMap" />
              </div> -->
              <dv-border-box-12 style="height: 30%">
                <right-province :regionLeaseInfoList="regionLeaseInfoList" :projectName="projectName" ref="inedxadd" />
              </dv-border-box-12>
              <dv-border-box-13 style="height: 70%">
                <right-county :communityInfoMap="communityInfoMap" :projectName="projectName" />
              </dv-border-box-13>
            </div>
            <div style="height:100%;width:30%" v-if="tabName==='PARK'">
              <!-- <div :reverse="true" style="height:60%">
                <park-rightbot :parkInfos="parkInfos" />
              </div>
              <div :reverse="true" style="height:40%">
                <park-righttop :pspn="parkSurplusNumber" :parkNumber="parkNumber" ref="add" />
              </div> -->
              <dv-border-box-12 :reverse="true" style="height:60%">
                <park-rightbot :parkInfos="parkInfos" />
              </dv-border-box-12>
              <dv-border-box-13 :reverse="true" style="height:40%">
                <park-righttop :pspn="parkSurplusNumber" :parkNumber="parkNumber" :projectName="projectName" ref="add" />
              </dv-border-box-13>
            </div>
            <div style="height: 100%; width: 30%" v-if="tabName === 'BLUE'">
              <dv-border-box-13 style="height: 100%">
                <blue-righttop :infos="infos" :projectName="projectName" ref="addblue" />
              </dv-border-box-13>
              <!-- <dv-border-box-1 style="height: 40%">
                <blue-rightbot :projectRentHouseCount="projectRentHouseCount" :projectRecentMountLeasedCount="projectRecentMountLeasedCount" :projectHouseCount="projectHouseCount" :projectLastMountLeasedCount="projectLastMountLeasedCount" ref="blueadd" />
              </dv-border-box-1> -->
            </div>
            <div style="height: 100%; width: 30%" v-if="tabName === 'SHOP'">
              <dv-border-box-12 style="height: 30%">
                <!-- <div  style="height: 30%"> -->
                <shoprightone :areaData="areaData"></shoprightone>
                <!-- </div> -->

              </dv-border-box-12>
              <dv-border-box-13 style="height: 70%">
                <!-- <div style="height: 70%"> -->
                <shoprighttop :infos="infos" :projectName="projectName" ref="addshop" />
                <!-- </div> -->

              </dv-border-box-13>
              <!-- <dv-border-box-1 style="height: 40%">
                <shoprightbot :projectRentHouseCount="projectRentHouseCount" :projectRecentMountLeasedCount="projectRecentMountLeasedCount" :projectHouseCount="projectHouseCount" :projectLastMountLeasedCount="projectLastMountLeasedCount" ref="blueadd" />
              </dv-border-box-1> -->
            </div>
            <div style="height: 100%; width: 30%" v-if="tabName === 'ARRANGE'">
              <!-- <div style="height: 40%">
                  <arrangerightone :suminfo="suminfo" :sumItems="sumItems" ref="arrangeadd" />
              </div>
              <div style="height: 60%">
                 <arrange-righttop :arrdata="arrdata" :projectName="projectName" />
              </div> -->
              <dv-border-box-12 style="height: 45%">
                <arrangerightone :suminfo="suminfo" :sumItems="sumItems" :projectName="projectName" ref="arrangeadd" />
              </dv-border-box-12>
              <dv-border-box-13 style="height: 55%">
                <arrange-righttop :arrdata="arrdata" :projectName="projectName" />
              </dv-border-box-13>
              <!--  <dv-border-box-12 style="height:65%">
                <arrange-rightbot />
              </dv-border-box-12> -->
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import { formatTime } from "../utils/index.js";
import topHeader from "./topHeader";
// import topNumber from "./topNumber";
import centerForm from "./centerForm";
import leftLevel from "./leftLevel";
import leftLoops from "./leftLoops";
import china from "./china";
// import chinaGaode from "./chinaGaode";
import rightProvince from "./rightProvince";
import rightCounty from "./rightCounty";
// import arrangeLeftbot from "./arrange/arrangeleftbot";
import arrangeLefttop from "./arrange/arrangeleftTop";
import arrangeRighttop from "./arrange/arrangerightTop";
import arrangerightone from './arrange/arrangerightone'
// import arrangeRightbot from "./arrange/arrangerightbot";
import blueLefttop from "./blue/blueleftTop";
import blueRighttop from "./blue/bluerightTop";
// import blueRightbot from "./blue/bluerightbot";
import parkLefttop from "./park/parkleftTop";

import parkRighttop from "./park/parkrightTop";
import parkRightbot from "./park/parkrightbot";
// import shopright from './shop/shopright'
// import rentLine from "@/views/rent/rentLine";
// import rentDouble from "@/views/rent/rentDouble";
// import rentPie from "@/views/rent/rentPie";
import rentTable from "@/views/rent/rentTable";
import rentPies from "@/views/rent/rentPies";
import rentHouse from "@/views/rent/rentHouse";
import rentOffice from "@/views/rent/rentOffice";
import rentShop from "@/views/rent/rentShop";
// import rentOther from "@/views/rent/rentOther";
import { weatherOption } from "../config/weatherOption";
import apis from '@/xhr/park'
import blueApi from '@/xhr/blue'
import shopApi from '@/xhr/shop'
import indexApi from '@/xhr/index'
import arrangeApi from '@/xhr/arrange'
import rentApi from '@/xhr/rent'
import shoplefttop from './shop/shoplefttop'
// import shoprightbot from './shop/shoprightbot'
import shoprighttop from './shop/shoprighttop'
import shoprightone from './shop/shoprighrone'
export default {
  name: "index",
  components: {
    rentHouse,
    rentOffice,
    rentShop,
    // rentOther,
    rentTable,
    // rentPie,
    rentPies,
    // rentDouble,
    // rentLine,
    topHeader,
    // topNumber,
    centerForm,
    leftLevel,
    leftLoops,
    china,
    shoplefttop,
    // shoprightbot,
    shoprighttop,
    // shopright,
    // chinaGaode,
    rightProvince,
    rightCounty,
    // arrangeLeftbot,
    arrangeLefttop,
    arrangeRighttop,
    arrangerightone,
    // arrangeRightbot,
    blueLefttop,
    blueRighttop,
    // blueRightbot,
    parkLefttop,
    parkRighttop,
    parkRightbot,
    shoprightone
  },
  data () {
    return {
      isTest: true,
      heiwidth: false,
      projectName: null,
      projectHouseCount: null,
      projectLastMountLeasedCount: null,
      projectRecentMountLeasedCount: null,
      projectRentHouseCount: null,
      arrdata: [],
      infos: [],
      rentTimer: null,
      rentData: [],
      communityInfoMap: [], // index 右侧数据
      regionLeaseInfoList: [],
      parkInfos: [],
      parkNumber: null,
      parkSurplusNumber: null,
      suminfo: {},
      sumItems: [],
      adress: null,
      adress1: null,
      tabName: "INDEX",
      screenHeight: "",
      loading: true,
      dataTime: {
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      },
      dataWeather: {
        city: "北京市",
        dayweather: "晴",
        nightweather: "多云",
        weathertemp: "晴", // 根据时间判断选白天还是晚上的天气
        nighttemp: "3℃",
        daytemp: "15℃",
        peraturetemp: "3℃~15℃", // 根据时间判断选白天还是晚上的温度
        icontemp: "https://cdn.heweather.com/cond_icon/100.png",
      },
      areaData: {
        areaCode: "000000",
        areaLevel: "county",
        name: "西湖区",
        areaName: "西湖区本级",
      },
    };
  },
  computed: {
    chinaMapTitle () {
      let areaName = this.areaData.areaName;
      if (areaName === "china") {
        areaName = "全国";
      }
      return areaName;
    },
  },
  created () {
    this.getDateTime();
    this.cancelLoading();
    this.requestGetWeather();


  },
  mounted () {

  },
  watch: {
    tabName (val) {
      if (val === 'RENT') {
        this.getRentData()
        // this.rentTimer = setInterval(() => {
        //   this.getRentData()
        // }, 10000)
      } else {
        // clearInterval(this.rentTimer)
      }
      let params = {
        region: '三墩镇',
      };
      if (this.tabName === "PARK") {
        this.getData(params);
      } else if (this.tabName === "BLUE") {
        this.getBlueData(params);
      } else if (this.tabName === "ARRANGE") {
        this.getArrangeData(params);
      } else if (this.tabName === "SHOP") {
        this.getShopData(params)
      }

    },
    adress (val, old) {
      if (val) {
        let params = {
          region: val,
        };
        if (this.tabName === "PARK") {
          this.getData(params);
        } else if (this.tabName === "BLUE") {
          this.getBlueData(params);
        } else if (this.tabName === "ARRANGE") {
          this.getArrangeData(params);
        } else if (this.tabName === "INDEX") {
          this.postIndexData(params)
        } else if (this.tabName === "SHOP") {
          this.getShopData(params)
        }
      }
    },
    adress1 (val, oldval) {
      if (val) {
        let params = {
          region: val,
        };
        if (this.tabName === "PARK") {
          this.getData(params);
        } else if (this.tabName === "BLUE") {
          this.getBlueData(params);
        } else if (this.tabName === "ARRANGE") {
          this.getArrangeData(params);
        } else if (this.tabName === "INDEX") {
          this.postIndexData(params)
        } else if (this.tabName === "SHOP") {
          this.getShopData(params)
        }
      }
    },
  },
  methods: {
    getRentData () {
      rentApi.rentInformation().then(res => {
        if (res.data.code === '200') {
          let data = res.data.data
          this.rentData = res.data.data
          // 租赁概况
          let totalData = [] //总量
          let rentedData = []//已出租
          let sameRate = [] //同比
          let chainRate = []//环比
          data.curLeaseInfo.forEach(el => {
            if (el.name === 'BLUE_APARTMENT' || el.name === 'SHOP') {
              // 蓝领和办公 单位是间
              el.totalCount === null ? totalData.push(0) : totalData.push(el.totalCount)//总间数
              el.rentedCount === null ? rentedData.push(0) : rentedData.push(el.rentedCount)//已出租
            } else {
              // 办公和其他 单位是平方
              el.totalArea === null ? totalData.push(0) : totalData.push(el.totalArea)//总面积
              el.rentedArea === null ? rentedData.push(0) : rentedData.push(el.rentedArea)//已出租
            }
            sameRate.push(el.sameRate)
            chainRate.push(el.chainRate)
          })
          // this.$refs.rentPie.draw(totalData, rentedData, sameRate, chainRate)
          // 本年租赁概况
          // let rentRoomNum = []
          // data.curYearRentedCountInfo.forEach(el => {
          //   rentRoomNum.push(el.value)
          // });
          // this.lastYearName = data.lastYearRentedCountInfo[0].month.substr(0, 4)
          // let roomNum = []
          // data.curYearTotalCountInfo.forEach(el => {
          //   roomNum.push(el.value)
          // });
          // let rentPro = []
          // data.lastYearRentedCountInfo.forEach(el => {
          //   rentPro.push(el.value)
          // });
          // this.$refs.rentDoublue.draw(rentRoomNum, roomNum, rentPro)
          // 双折线 历年租赁概况
          // let twoYeaName = data.twoYearsAgoRentedCountInfo[0].month.substr(0, 4)
          // let twoYeaData = []
          // data.twoYearsAgoRentedCountInfo.forEach(el => {
          //   twoYeaData.push(el.value)
          // });
          // let lastYearName = data.lastYearRentedCountInfo[0].month.substr(0, 4)
          // let lastYearData = []
          // data.lastYearRentedCountInfo.forEach(el => {
          //   lastYearData.push(el.value)
          // });
          // this.$refs.rentLine.draw(twoYeaName, twoYeaData, lastYearName, lastYearData)
          // 公寓去年
          let rentHouse = []
          let houseMounth = []
          data.apartCurrYearMonthRentedCountInfo.forEach(el => {
            rentHouse.push(el.value)
            houseMounth.push(el.month)
          })
          this.$refs.rentHouse.draw(rentHouse, houseMounth)
          // 办公去年
          let rentOffice = []
          let officeMounth = []
          data.officeCurrYearMonthRentedCountInfo.forEach(el => {
            rentOffice.push(el.value)
            officeMounth.push(el.month)
          })
          this.$refs.rentOffice.draw(rentOffice, officeMounth)
          // 商铺去年
          let rentShop = []
          let shopMounth = []
          data.shopCurrYearMonthRentedCountInfo.forEach(el => {
            rentShop.push(el.value)
            shopMounth.push(el.month)
          })
          this.$refs.rentShop.draw(rentShop, shopMounth)
          // 其他去年
          let rentOther = []
          let otherMounth = []
          data.otherCurrYearMonthRentedCountInfo.forEach(el => {
            rentOther.push(el.value)
            otherMounth.push(el.month)
          })
          // this.$refs.rentOther.draw(rentOther, otherMounth)
          //饼图 小区公寓
          // 处理饼图数据 从大到小排序 取前十个展示
          data.apartRentedInfo.sort((a, b) => {
            return b.value - a.value
          })
          let maxArr = []
          if (data.apartRentedInfo.length > 5) {
            // splice 方法改变原数组=> communityArea
            maxArr = data.apartRentedInfo.splice(0, 5)
            // 截取前十位 剩余数据相加为其他
            let otherNum = 0;
            data.apartRentedInfo.forEach(el => {
              otherNum = otherNum + el.value
            })
            maxArr.push({ value: otherNum, name: '其他' })
          } else {
            maxArr = data.apartRentedInfo
          }
          this.$refs.rentPies.drawHome(maxArr)
          //饼图 小区商铺
          // 处理饼图数据 从大到小排序 取前十个展示
          data.shopRentedInfo.sort((a, b) => {
            return b.value - a.value
          })
          let maxShop = []
          if (data.shopRentedInfo.length > 5) {
            // splice 方法改变原数组=> communityArea
            maxShop = data.shopRentedInfo.splice(0, 5)
            // 截取前十位 剩余数据相加为其他
            let otherShopNum = 0;
            data.shopRentedInfo.forEach(el => {
              otherShopNum = otherShopNum + el.value
            })
            maxShop.push({ value: otherShopNum, name: '其他' })
          } else {
            maxShop = data.shopRentedInfo
          }
          this.$refs.rentPies.drawShop(maxShop)
        } else {
          this.$message.error(res.data.message)
        }

      })
    },
    watchAdress (data) {
      this.adress = data
    },
    watchAdress1 (data) {
      this.adress1 = data
    },
    // 请求图表数据 停车场
    getData (params) {
      apis.postRegionData(params).then(res => {
        if (res.data.code === '200') {
          if (res.data.data) {
            this.parkSurplusNumber = res.data.data.parkSurplusNumber
            this.parkNumber = res.data.data.parkNumber
            this.parkInfos = res.data.data.parkInfos
            this.projectName = res.data.data.projectName
            this.$nextTick(() => {
              this.$refs.add.drawA(this.parkNumber, this.parkSurplusNumber)
            })
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 请求数据 首页
    postIndexData (params) {
      // let a = {
      //   region : params
      // }
      indexApi.postRegionData(params).then((res) => {
        if (res.data.code === "200") {
          //  console.log(res.data, "index数据");
          if (res.data.data) {
            this.regionLeaseInfoList = res.data.data.regionLeaseInfoList
            this.communityInfoMap = res.data.data.communityInfoMap
            this.projectName = res.data.data.projectName
          }
          // this.$refs.inedxadd.drawRate(this.regionLeaseInfoList)
        }
      })
    },
    getBlueData (params) {
      // let params = {
      //   region: val
      // }
      blueApi.getRightData(params).then((res) => {
        if (res.data.code === "200") {
          if (res.data.data) {
            this.infos = res.data.data.infos;
            this.projectHouseCount = res.data.data.projectHouseCount
            this.projectLastMountLeasedCount = res.data.data.projectLastMountLeasedCount
            this.projectRecentMountLeasedCount = res.data.data.projectRecentMountLeasedCount
            this.projectRentHouseCount = res.data.data.projectRentHouseCount
            this.projectName = res.data.data.projectName
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getShopData (params) {
      shopApi.getRightData(params).then((res) => {
        if (res.data.code === "200") {
          if (res.data.data) {
            this.infos = res.data.data.infos;
            (this.projectHouseCount = res.data.data.projectHouseCount),
              (this.projectLastMountLeasedCount =
                res.data.data.projectLastMountLeasedCount),
              (this.projectRecentMountLeasedCount =
                res.data.data.projectRecentMountLeasedCount),
              (this.projectRentHouseCount = res.data.data.projectRentHouseCount);
            this.projectName = res.data.data.projectName
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getArrangeData (params) {
      arrangeApi.getRightData(params).then((res) => {
        if (res.data.code === "200") {
          if (res.data.data) {
            if (res.data.data.infos && res.data.data.infos.length > 0) {
              this.arrdata = res.data.data.infos;
            } else {
              this.arrdata = []
            }
            if (res.data.data.sumItems && res.data.data.sumItems.length > 0) {
              this.sumItems = res.data.data.sumItems
              this.$nextTick(() => {
                this.$refs.arrangeadd.drawRate1(this.sumItems)
              });
            } else {
              this.sumItems = []
            }
            if (res.data.data.sumInfo) {
              this.suminfo = res.data.data.sumInfo
            } else {
              this.sumInfo = {}
            }
            this.projectName = res.data.data.projectName
          }

        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 获取当前模块名称
    tabChange (name) {
      this.tabName = name;
      if (this.name !== 'RENT') {
        this.isTest = false
        this.$nextTick(() => {
          this.isTest = true
        })
      } else {
        this.isTest = false
      }
      if (this.tabName === 'SHOP') {
        this.heiwidth = false
      } else {
        this.heiwidth = false
      }
    },
    // 获取时间
    getDateTime () {
      setInterval(() => {
        this.dataTime.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dataTime.dateWeek = this.dataTime.weekday[new Date().getDay()];
        this.dataTime.dateDay = formatTime(new Date(), "HH: mm: ss");
      }, 1000);
    },
    // 获取天气
    requestGetWeather () {
      $.get({
        //这里的url不能写到data对象的外面
        url: "https://api.asilu.com/weather_v2/",
        dataType: "jsonp", // 请求方式为jsonp
        success: (data) => {
          if (data.status === "1") {
            let currTime = new Date().getHours();
            let dailyForecast = data.forecasts[0].casts[0];
            let city = data.forecasts[0].city;
            let daytemp = dailyForecast.daytemp + "℃";
            let nighttemp = dailyForecast.nighttemp + "℃";
            let weathertemp = "";
            let peraturetemp = "";
            if (currTime >= 6 && currTime < 18) {
              weathertemp = dailyForecast.dayweather;
              peraturetemp =
                dailyForecast.nighttemp + "℃~" + dailyForecast.daytemp + "℃";
            } else {
              weathertemp = dailyForecast.nightweather;
              peraturetemp =
                dailyForecast.daytemp + "℃~" + dailyForecast.nighttemp + "℃";
            }
            let icontemp =
              "https://cdn.heweather.com/cond_icon/" +
              weatherOption.weatherCode[weathertemp] +
              ".png";
            this.dataWeather = {
              city,
              daytemp,
              nighttemp,
              weathertemp,
              peraturetemp,
              icontemp,
            };
          }
        },
      });
    },

    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    mapClick (data) {
      this.areaData = data;
    },
    backClick (data) {
      this.areaData = data;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/index.scss";
</style>
<style lang="scss" scoped>
#index {
  .dv-border-box-10,
  .dv-border-box-12,
  .dv-border-box-13 {
    // height: inherit;
    height: 50%;
    // overflow: hidden;
  }
  .body-box {
    height: 92%;
    .info-four {
      z-index: 999;
      position: relative;
      height: 6%;
    }
    .special {
      height: 94%;
    }
  }
  .dataweather {
    z-index: 999;
    position: absolute;
    top: 0.1rem;
    left: 1.1rem;
    font-size: 0.22rem;
    color: #fff;
    img {
      width: 0.3rem;
      height: 0.3rem;
      filter: brightness(3);
      vertical-align: bottom;
    }
  }
  .datatime {
    z-index: 999;
    position: absolute;
    top: 0.2rem;
    right: 1.1rem;
    font-size: 0.22rem;
    color: #fff;
  }
  .topNumber {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .rent {
    display: flex;
  }
  .rentLeft,
  .rentRight {
    height: 100%;
    width: 50%;
  }
}
.china {
  height: 100%;
  width: 40%;
}
.width1 {
  width: 70%;
}
::v-deep .double .border-box-content {
  display: flex;
}
.chinaslice {
  height: 120%;
  width: 120%;
  margin-top: -10%;
  margin-left: -10%;
  z-index: -999;
}
</style>
