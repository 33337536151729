let parkUrl='path://M511.998465 57.264215c-204.053396 0-369.471866 165.417447-369.471866 369.472889 0 47.588855 8.999978 93.073792 25.38618 134.852314 0 0 0.001023 0.004093 0.002047 0.004093 5.528923 14.097066 11.899004 27.773554 19.047822 40.962947 40.071647 79.612199 144.580886 256.800737 325.035818 364.178303 180.420139-107.355053 284.923238-284.496519 325.011258-364.130208 28.356838-52.29504 44.461631-112.200408 44.461631-175.86745C881.471354 222.682685 716.053907 57.264215 511.998465 57.264215zM512.000512 744.713132c-173.35421 0-313.88485-140.53064-313.88485-313.883827S338.646302 116.945479 512.000512 116.945479c173.352163 0 313.882803 140.529617 313.882803 313.882803S685.352675 744.713132 512.000512 744.713132z M467.55423 500.81117c10.614755 2.106987 24.27487 2.814092 39.423895 2.814092 68.248384 0 126.639259-16.179494 166.063153-49.954739 31.106462-26.030863 47.02399-65.427128 47.02399-112.557542 0-46.432519-21.228487-85.829807-54.590316-109.045555-34.133402-26.031886-84.941578-38.697347-156.220994-38.697347-70.508867 0-120.561843 4.222161-156.203598 9.850345l0 467.841268 114.50387 0L467.55423 500.81117 467.55423 500.81117zM467.55423 278.498922c8.335852-2.114151 24.27487-3.52222 47.00557-3.52222 58.391898 0 91.00262 26.031886 91.00262 69.648266 0 47.839565-37.916565 76.688613-99.336426 76.688613-17.442254 0-28.809139-0.700965-38.670741-2.816139L467.555254 278.498922 467.55423 278.498922zM467.55423 278.498922'
let shopUrl='path://M503.336 55.2c-208 0-376 169.6-376 378.4 0 104.8 42.4 199.2 110.4 267.2l265.6 268 265.6-267.2c70.4-71.2 110.4-167.2 110.4-267.2 0-209.6-168-379.2-376-379.2z m174.216 328.504v0.024l-4.904 5.272c-6.968 7.568-16.208 10.616-20.584 6.808l-7.952-6.88v164.128c0 10.224-8.432 18.496-18.8 18.496h-71.336c-10.384 0-17.808-8.272-17.648-18.48V436.632H450.48v116.448c0 10.224-8.44 18.48-18.8 18.48h-67.304c-10.408 0-18.8-8.272-18.8-18.48V386.616l-12.928 11.368c-7.104 6.28-18.728 5.384-25.904-1.952l-4.336-4.448a17.768 17.768 0 0 1 0.8-25.832l172.728-155.72a21.728 21.728 0 0 1 27.992-0.32l172.104 148.152 0.632 0.56c7.232 6.744 7.624 18.056 0.888 25.28z'
let arrangeUrl='path://M426.666667 375.466667l68.266666 0 0 68.266666-68.266666 0 0-68.266666Z M546.133333 494.933333h-68.266666a17.066667 17.066667 0 0 0-17.066667 17.066667v51.2h102.4v-51.2a17.066667 17.066667 0 0 0-17.066667-17.066667z M426.666667 273.066667l68.266666 0 0 68.266666-68.266666 0 0-68.266666Z M529.066667 273.066667l68.266666 0 0 68.266666-68.266666 0 0-68.266666Z M529.066667 375.466667l68.266666 0 0 68.266666-68.266666 0 0-68.266666Z M513.706667 34.133333A394.24 394.24 0 0 0 119.466667 428.373333c0 196.266667 173.226667 286.549333 264.704 386.218667 104.789333 114.176 129.706667 183.296 129.706666 183.296s23.381333-68.266667 127.488-181.077333C733.866667 716.8 907.946667 625.834667 907.946667 428.373333A394.24 394.24 0 0 0 513.706667 34.133333z m177.493333 580.266667h-358.4a25.6 25.6 0 0 1 0-51.2H375.466667V221.866667a17.066667 17.066667 0 0 1 17.066666-17.066667h238.933334a17.066667 17.066667 0 0 1 17.066666 17.066667v341.333333h42.666667a25.6 25.6 0 0 1 0 51.2z'
let blueUrl='path://M78,0 C121.07821,0 156,34.9217895 156,78 C156,134 80.5,185.5 78,188.5 C75.5,185.5 0,134 0,78 C0,34.9217895 34.9217895,0 78,0 Z M114.794922,86.2758789 L40.2050781,86.2758789 L40.2050781,110.025879 L31.6699219,110.025879 L31.6699219,120.416504 L123.422852,120.416504 L123.422852,110.025879 L114.794922,110.025879 L114.794922,86.2758789 Z M102.548828,96.2954102 L102.548828,110.025879 L93.7353516,110.025879 L93.7353516,96.2954102 L102.548828,96.2954102 Z M61.2646484,96.2954102 L61.2646484,110.025879 L52.4511719,110.025879 L52.4511719,96.2954102 L61.2646484,96.2954102 Z M81.8603516,96.2954102 L81.8603516,110.025879 L73.1396484,110.025879 L73.1396484,96.2954102 L81.8603516,96.2954102 Z M82.0458984,52.3208008 C79.2626953,62.340332 75.5208333,71.0610352 70.8203125,78.4829102 C76.015625,81.3279622 79.3245443,83.3999023 80.7470703,84.6987305 C83.9322917,79.9054362 86.7773438,74.3081055 89.2822266,67.9067383 L97.4462891,67.9067383 L91.7871094,73.565918 C97.6009115,77.957194 102.456055,82.1319987 106.352539,86.090332 L114.331055,77.9262695 C110.836589,74.7101237 106.754557,71.3702799 102.084961,67.9067383 L121.567383,67.9067383 L121.567383,57.887207 L92.7148438,57.887207 L93.6425781,54.6401367 L82.0458984,52.3208008 Z M69.5214844,54.3618164 L57.6464844,54.3618164 L57.6464844,82.8432617 L69.5214844,82.8432617 L69.5214844,54.3618164 Z M49.2041016,55.5678711 L37.3291016,55.5678711 L37.3291016,82.8432617 L49.2041016,82.8432617 L49.2041016,55.5678711 Z M69.3359375,31.9106445 L57.0898438,31.9106445 L57.0898438,38.0336914 L31.5771484,38.0336914 L31.5771484,48.4243164 L57.0898438,48.4243164 L57.0898438,52.4135742 L69.3359375,52.4135742 L69.3359375,48.4243164 L86.3134766,48.4243164 L86.3134766,52.3208008 L98.5595703,52.3208008 L98.5595703,48.4243164 L123.608398,48.4243164 L123.608398,38.0336914 L98.5595703,38.0336914 L98.5595703,31.9106445 L86.3134766,31.9106445 L86.3134766,38.0336914 L69.3359375,38.0336914 L69.3359375,31.9106445 Z'
let indexUrl=''
export {
    blueUrl,
    parkUrl,
    shopUrl,
    arrangeUrl,
    indexUrl
}