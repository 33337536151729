  <template>
  <div id="leftLevel">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2" style="height:4%">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
        
          <template>
            <span class="fs-xl text mx-2">商铺基本信息</span>
          </template>
          <dv-decoration-1 style="
                width: 1.25rem;
                height: 0.25rem;
                position: relative;
                top: -0.0375rem;
              " />
        </div>
      </div>
      
        <div class="histogram-info">
           <Company :company = 'company'/>
          <div id="myChart"></div>
        </div>
      </div>
  

  </div>
</template>

<script>
import Company from '../Company'
import indexApi from "@/xhr/shop";
export default {
  name: "leftLevel",
  props: {
    areaData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Company
  },
  watch: {
    areaData(val) {
      this.areaDataObj = val;
    },
  },
  data() {
    return {
      company:'万/平方',
      indexData: {},
      currReginInfos: {},
       addressarr : [],
          totalcounts : [],
          rentedcounts : [],
      areaDataObj: {
        areaCode: "410000",
        areaLevel: "province",
        name: "西湖区",
        areaName: "西湖区",
      },
      config: {
        name: "区块名称",
        value: 40,
        lineDash: [1, 0],
        borderWidth: 2,
        borderGap: -1,
        borderRadius: 2,
        colors: ["#01c4f9", "#c135ff"],
        done: 10,
        all: 20,
        formatter: "", //{value}%
      },
      myChartDrawer: null,
    };
  },
  created() {
    this.getIndexData();
  },
  mounted() {
    // this.getIndexData()
    // this.draw();
    let _this = this;
    window.addEventListener("resize", function () {
      if (_this.resizeTimer) clearTimeout(_this.resizeTimer);
      _this.resizeTimer = setTimeout(function () {
        _this.myChartDrawer.resize();
      }, 100);
    });
    this.timer = window.setInterval(() => {
      _this.getIndexData();
    }, 60000);
  },
  destroyed() {
    //  销毁定时器
    window.clearInterval(this.timer);
  },
  methods: {
    getIndexData() {
      indexApi.getLeftData().then((res) => {
        if (res.data.code === "200") {
          this.indexData = res.data.data;
          this.currReginInfos = this.indexData.currRegionInfos;
          this.addressarr = []
          this.totalcounts = []
          this.rentedcounts = []
          this.currReginInfos.forEach(el => {
              this.addressarr.push(el.region)
              this.totalcounts.push(el.totalCount)
              this.rentedcounts.push(el.rentedCount)
          });
          this.draw();
        }
      });
    },
    draw() {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("myChart")
      );
      this.myChartDrawer = myChartDrawer;
      // 绘制条形图
      var option = {
        label:{
            color:'#fff'
        },
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
           formatter : function(params) {
           var result = params[0].name;
            params.forEach(function(item) {
                if (item.data) {
                    result += '<br/>' + item.data + '平方';
                }
            });
            return result;
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.addressarr,
            axisTick: {
              alignWithLabel: true,
              color: "#fff",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 600,
                fontSize: 10,
              },
            },
            // 坐标轴颜色
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#1C4274",
                width: "1",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              // show: false,// 取消刻度线
              lineStyle: {
                type: "solid",
                color: "#1C4274",
                width: "1",
              },
            }, //
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 800,
                fontSize: 14,
              },
               formatter : function(value, index) {
                if(value != 0)
                return (value/10000).toFixed(1) ;
                else
                return 0;
                }
            },
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#1C4274",
                width: "1",
              },
            },
          },
        ],
        series: [
          
          {
            name: "总数",
            type: "bar",
            // barWidth: "10%",
            data: this.totalcounts,
             label: {
                show: true,
                position: 'top',
                formatter: function (params) { 
                let pna =  ((params.value)/10000).toFixed(1)
                return pna;
              },
            },
            itemStyle: {
              normal: {
                // color: function (params) {
                //   var colorlist = ['#DA251D', '#E67716', '#FBC300', '#11440f', '#32585a', '#00ff77'];
                //   return colorlist[params.dataIndex];
                // },
                color: function (params) {
                  // var colorlist = ['#1FC9F4', '#1FB9F4', '#1895E5', '#1870E5', '#185AE5', '#1845E5'];
                  var colorlist = [
                    "#185AE5",
                    "#185AE5",
                    "#185AE5",
                    "#185AE5",
                    "#185AE5",
                    "#185AE5",
                  ];
                  return colorlist[params.dataIndex];
                },
              },
            },
          },
          {
            name: "已出租",
            type: "bar",
            // barWidth: "10%",
            data: this.rentedcounts,
            label: {
                show: true,
                position: 'top',
                formatter: function (params) { 
                let pna =  ((params.value)/10000).toFixed(1)
                return pna;
              },
            },
            itemStyle: {
              normal: {
                // color: function (params) {
                //   var colorlist = ['#DA251D', '#E67716', '#FBC300', '#11440f', '#32585a', '#00ff77'];
                //   return colorlist[params.dataIndex];
                // },
                color: function (params) {
                  // var colorlist = ['#1FC9F4', '#1FB9F4', '#1895E5', '#1870E5', '#185AE5', '#1845E5'];
                  var colorlist = [
                   "#1FC9F4",
                    "#1FC9F4",
                    "#1FC9F4",
                    "#1FC9F4",
                    "#1FC9F4",
                    "#1FC9F4",
                   
                  ];
                  return colorlist[params.dataIndex];
                },
              },
            },
          },
        ],
      };

      myChartDrawer.setOption(option);
    },
    sliderScroll() {
      let scroll = document.getElementsByClassName("scroll-box");
      let parent = document.getElementById("parent");
      let time = setInterval(function () {
        parent.scrollTop++;
      }, 100);
      scroll[0].addEventListener("mouseover", function () {
        clearInterval(time);
      });
      scroll[0].addEventListener("mouseout", function () {
        time = setInterval(function () {
          parent.scrollTop++;
        }, 100);
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
#leftLevel {
  padding: 0.2rem;
  height: 100%;
  width: 100%;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 100%;
    width: 100%;
    border-radius: 0.125rem;
  }

  .text {
    font-size: 0.25rem;
    font-weight: bold;
    color: #fff;
  }
  #parent::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  #parent {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: hidden;
  }
}
.assets-info::after {
  content: "";
  width: 32%;
}
.assets-info {
  padding: 0.15rem 0.15rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 50%;
  width: 100%;
}
.histogram-info {
  position: relative;
  height: 100%;
}
#myChart {
  width: 100%;
  height: 100%;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}

.asset-bg {
  box-shadow: #2ae9f2 0px 0px 0.15rem inset;
  border-radius: 0.05rem;
  background-color: #1d1e2f;
  width: 32%;
  height: 48%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-content: center;
  margin-bottom: 0.15rem;

  .num {
    font-size: 0.35rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
    span {
      color: #808080;
      font-size: 0.2rem;
    }
  }
  .desc {
    width: 100%;
    line-height: 100%;
    background: linear-gradient(#2ae9f2, #1471b7);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
