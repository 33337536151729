<template>
  <div id="rightProvince">
    <swiper class="swiper" style="height: 100%" v-if="this.arrdata.length">
      <swiper-slide class="swiper-slide" v-for="(item, index) in arrdata" :key="index">
        <div class="bg-color-black">
          <div>
            <div class="font_size font_top title">
              <span style="color: #5cd9e8;text-indent:0">
                <icon name="chart-pie"></icon>
              </span>
              {{item.desecurity}}
            </div>
            <div class="font_size ">
              建设主体: <span> {{item.buildDepart || '暂无信息'}}</span>
            </div>
            <div class="font_size">
              属地镇街: <span> {{item.belongStreet || '暂无信息'}}</span>
            </div>
            <div class="font_size">
              安置率: <span> {{((item.typeNum.plantCount/(item.typeNum.arrangeCount + item.typeNum.allotCount +
              item.typeNum.borrowCount + item.typeNum.otherCount + item.typeNum.blueCount + item.typeNum.plantCount
              ))*100).toFixed()+'%'|| '暂无信息'}}</span>
               <Company :company='company' />
            </div>
            
          </div>

          <div class="cell_data">
             
            <!-- <div :id="'Rehousing_tate' + index" :ref="'Rehousing_tate' + index" class="Rehousing_tate"></div> -->
            <div class="charts" :id="'charts' + index" :ref="'charts' + index"></div>
          </div>

        </div>
      </swiper-slide>
    </swiper>
    <div v-if="!this.arrdata || this.arrdata.length ===0 " class="center">暂无信息</div>
  </div>
</template>

<script>
import Company from '../Company'
export default {
  name: "rightProvince",
  data () {
    return {
      company: '套',
      slide: [1, 2],
    };
  },
  mounted () {

  },
  components: {
    Company
  },
  props: ['arrdata'],
  watch: {
    arrdata (val) {
      if (val) {
        setTimeout(() => {
          val.forEach((item, index) => {
            let obj = [
              { value: item.typeNum.borrowCount, name: "借用" },
              { value: item.typeNum.allotCount, name: "预留安置" },
              { value: item.typeNum.arrangeCount, name: "空置" },
              { value: item.typeNum.plantCount, name: "已安置" },
              { value: item.typeNum.otherCount, name: "其他" },
              { value: item.typeNum.blueCount, name: "蓝领" },
            ]
            this.PieDiagram(index, obj);
            let total = item.typeNum.arrangeCount + item.typeNum.allotCount +
              item.typeNum.borrowCount + item.typeNum.otherCount + item.typeNum.blueCount + item.typeNum.plantCount
  
            // this.draw(index, item.typeNum.plantCount, total);
          })
        }, 500);

      }
    }
  },
  methods: {
    PieDiagram (ind, data) {
      // 实例化echarts对象
      let name = 'charts' + ind
      let refcharts = this.$refs[name][0]
      if (refcharts) {
        let myChartDrawer = this.$echarts.init(
          // document.getElementById(name)
          refcharts
        );
        var option = {
          label:{
            color:'#fff'
        },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c}套 ({d}%)",
          },
          series: [
            // {
            //   name: "",
            //   type: "pie",
            //   radius: "50%",
            //   center: ["50%", "40%"],
            //   data: data,
            //   itemStyle: {
            //     color: function (params) {
            //       var colorlist = ['#4FE0B6', '#15CDCA', '#42A5F5', '#C7CF77', '#3E60C1', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
            //       return colorlist[params.dataIndex];
            //     },
            //   },
            //   emphasis: {
            //     itemStyle: {
            //       shadowBlur: 10,
            //       shadowOffsetX: 0,
            //       shadowColor: "rgba(0, 0, 0, 0.2)",
            //     },
            //   },
            //   label: {
            //     normal: {
            //       formatter: '{b}\n{d}%',
            //       textStyle: {
            //         fontSize: 12, //指示文字
            //       },
            //     },
            //   },
            // },
             {
            data: data,
            name: "",
            type: "pie",
            radius: "30%",
            center: ["50%", "30%"],
            itemStyle: {
              color: function (params) {
                // var colorlist = ['#DE2425', '#2CF9DC', '#178BF9', '#FDDC55', '#F47D7A', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                var colorlist = ['#1989EA', '#156AE4', '#C1BF52', '#C49741', '#4FE0B6', '#44B079', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                // var colorlist = ['#CBD435', '#f6eeda', '#f29a8b', '#e06641', '#F47D7A']
                return colorlist[params.dataIndex];
              },
            },
            height: '100%',
            left: 'center',
            width: '90%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            label: {
              color: '#fff',
              alignTo: 'edge',
              formatter: '{name|{b}}\n{time|{c} ({d}%)}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 20,
              // color: 'red',
              rich: {
                time: {
                  fontSize: 14,
                  color: '#fff'
                }
              }
            },
            labelLine: {
              length: 20,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout (params) {
              var isLeft = params.labelRect.x < myChartDrawer.getWidth() / 2;
              var points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;

              return {
                labelLinePoints: points
              };
            },
          },
          ],
        };
        myChartDrawer.setOption(option);
      }
      //  }else {
      //    console.log('DOm未加载完成！！！')
      //  }
    },
    draw (ind, val, total) {
      // 实例化echarts对象
      let name = 'Rehousing_tate' + ind
      let refrehousing = this.$refs[name][0]
      if (refrehousing) {
        let myChartDrawer = this.$echarts.init(
          // document.getElementById(name)
          refrehousing
        );

        // 绘制环形 7CCFD3 29D9E8 299DC0 285283
        var option = {
          label:{
            color:'#fff'
          },
          series: [
            {
              type: "pie",
              radius: ["40%", "50%"],
              center: ["50%", "30%"],
              data: [
                {
                  name: "安置率",
                  value: val,
                  itemStyle: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 1,
                      x2: 0,
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#356eff", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#9bfcaf", // 100% 处的颜色
                        },
                      ],

                      global: false, // 缺省为 false
                    },
                  },
                  label: {
                    show: true, //单独显示该数据项
                    formatter: "安置率" + "\n" + "{d}%",
                    color: "#49c3e3", // 100% 处的颜色
                    fontSize: "20",
                    fontWeight: "bold",
                  },
                },
                {
                  value: total,
                  itemStyle: {
                    color: "#ccc",
                  },
                  label: {
                    emphasis: {
                      show: false,
                    },
                  },
                },
              ],
              itemStyle: {
                normal: {},
              },
              label: {
                normal: {
                  //默认不显示数据
                  show: false,
                  position: "center",
                },
                color: "#fff",
              },
            },
          ],
        };
        myChartDrawer.setOption(option);
      }
      // } else {
      //   console.log('Dom未加载完成')
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.font_top {
  margin-top: 20px;
}
.font_size {
  position: relative;
  padding: 0.1rem 0;
  line-height: 0.25rem;
  font-size: 0.25rem;
  font-weight: 600;
  color: #fff, span {
    font-weight: 600;
    text-indent: 0.3rem;
    display: inline-block;
  }
}
.title {
  font-size: 0.25rem;
  font-weight: bold;
  color: #fff;
  text-align: left !important;
}
.cell_data {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  // .Rehousing_tate {
  //   flex: 1;
  //   // width: 50%;
  //   height: 100%;
  //   clear: both;
  //   box-sizing: border-box;
  //   margin: 0px auto;
  // }
  .charts {
    flex: 1;
    //  width: 50%;
    width: 100%;
    height: 100%;
    clear: both;
    box-sizing: border-box;
    margin: 0px auto;
  }
  // .cell {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   .xinxi {
  //     height: 100%;
  //     font-size: 0.2rem;
  //     font-weight: bold;
  //     flex: 1;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;
  //     // background: rgb(167, 173, 113);
  //     p {
  //       font-size: 0.25rem;
  //       font-weight: bold;
  //       line-height: 0.2rem;
  //       // text-align: left;
  //       // width: 100%;
  //       margin-left: -1.2rem;
  //       padding-bottom: 0.1rem;
  //     }
  //     h5 {
  //       font-size: 0.4rem;
  //       line-height: 0.4rem;

  //       span {
  //         font-size: 0.2rem;
  //       }
  //     }
  //   }
  // }
}
// .center_cont {
//   display: flex;
//   width: 100%;
//   height: 70%;
//   //  background-color: #fff;
//   .data_Resettlement {
//     //  background: burlywood;
//     width: 40%;
//     height: 100%;
//     display: flex;
//     // flex-direction: column;
//     // .xinxi {
//     //   height: 100%;
//     //   font-size: 0.2rem;
//     //   font-weight: bold;
//     //   flex: 1;
//     //   display: flex;
//     //   align-items: center;
//     //   // justify-content: center;
//     //   flex-direction: column;
//     //   // background: rgb(167, 173, 113);
//     //   p {
//     //     font-size: 0.2rem;
//     //     font-weight: bold;
//     //     line-height: 0.2rem;
//     //     // text-align: left;
//     //     // width: 100%;
//     //     z-index: 0px;
//     //     margin-left: -1rem;
//     //     padding-bottom: 0.1rem;
//     //   }
//     //   h5 {
//     //     font-size: 0.4rem;
//     //     line-height: 0.4rem;

//     //     span {
//     //       font-size: 0.2rem;
//     //     }
//     //   }
//     // }
//   }
//   .Rehousing_tate {
//     width: 60%;
//     height: 100%;
//   }
// }
#rightProvince {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  // background: rgb(124, 146, 218);

  .bg-color-black {
    // overflow: hidden;
    padding: 0 0.3rem;
    // height: 4.8125rem;
    width: 100%;
    height: 100%;
    // background: burlywood;
    // border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
}
.d-flex.pt-2 {
  height: 6%;
}
.histogram-info {
  height: 94%;
  width: 100%;
}
#myChartsRight {
  width: 90%;
  height: 100%;
  min-height: 220px;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}
.border_bot {
  border-bottom: 2px solid rgb(88, 88, 177);
}
.title_top {
  width: 100%;
  padding-top: 0.2rem;
  // line-height: 0.3rem;
  // font-size: 0.2rem;
  color: #ffffff;
}
.center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.6rem;
  // text-align: center;
  // line-height: 100%;
  // height: 100%;
  // font-size: 40px;
  // margin-top: 20%;
  // font-family: "Courier New", Courier, monospace;
}
</style>
