<template>
  <div style="height:100%">
    <dv-border-box-12 style="height:55%">
      <!-- <div style="height:55%"> -->
      <div id="leftLevel">
        <div class="bg-color-black">
          <div class="d-flex pt-2 pl-2">
            <span style="color: #5cd9e8">
              <icon name="chart-pie"></icon>
            </span>
            <div class="text d-flex"><span class="fs-xl text mx-2">停车场信息</span></div>
          </div>
          <div class="num_title">
            <div class="number">
              <p>可出租车位总数</p>
              <div>{{parkingSpaceRentableNumber | changeNum}}<span class="unit">个</span></div>
            </div>
            <div class="number">
              <p>已出租车位数</p>
              <div>{{totalData.parkingSpaceRentedNumber | changeNum}}<span class="unit">个</span></div>
            </div>
            <div class="number">
              <p>停车场数</p>
              <div>{{totalData.parkTotal | changeNum}}<span class="unit">个</span></div>
            </div>
          </div>
          <div class="datav">
            <div id="chartsA" class="chart_one" ref="chartsA"></div>
            <div id="chartsB" class="chart_one"></div>
          </div>
        </div>
      </div>
    </dv-border-box-12>
    <!-- </div> -->
    <!-- <div style="height:45%"> -->
    <dv-border-box-13 style="height:45%">
      <div id="leftLoops">
        <div class="bg-color-black">
          <div class="d-flex pt-2 pl-2">
            <span style="color: #5cd9e8">
              <icon name="chart-line"></icon>
            </span>
            <div class="d-flex">
              <span class="fs-xl text mx-2">每月包月数</span>
            </div>
          </div>
          <div class="histogram-info">
            <Company :company='company' />
            <div id="myCharts"></div>
          </div>
        </div>
      </div>
    </dv-border-box-13>
    <!-- </div> -->
  </div>
</template>

<script>
import Company from '../Company'
import apis from '@/xhr/park'
import { changeNum } from '@/filters/thousand'
export default {
  name: "leftLevel",
  filters: {
    changeNum
  },
  components: {
    Company
  },
  data () {
    return {
      company: '个',
      timer: null,
      totalData: [],
      monthNum: [],
      month:[]
    };
  },

  created () {
    // this.getData()
    this.getData()
  },
  mounted () {
    // 定时器
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.getData()
      }, 0)
    }, 60000)
  },

  destroyed () {
    //  销毁定时器
    window.clearInterval(this.timer)
  },
  computed: {
    parkingSpaceRentableNumber () {
      let pspn = this.totalData.parkingSpaceRentableNumber;
      if (pspn < 0) {
        pspn = 0
      }
      return pspn
    }
  },
  methods: {

    // 请求图表数据
    getData () {
      apis.getLeftData().then(res => {
        if (res.data.code === '200') {
          let data = res.data.data
          this.totalData = data
          data.thisYearInfo.forEach(el => {
            this.monthNum.push(el.value)
            this.month.push(el.month)
          });
          this.$nextTick(() => {
            this.drawLine(this.monthNum,this.month)
            this.drawA(data.lastMonthParkingSpaceRentedNumber, data.lastMonthParkingSpaceTotal);
            this.drawB(data.nowMonthParkingSpaceRentedNumber, data.nowMonthParkingSpaceTotal);
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // changeNum () {
    //   this.config.number[0] === 100 ?
    //     this.config = {
    //       number: [999],
    //       content: '{nt}个'
    //     } : this.config = {
    //       number: [100],
    //       content: '{nt}个'
    //     }
    // }, //翻牌器

    drawA (val, total) {
      if (val < 0) {
        val = 0
      }
      if (val > total) {
        val = total
      }

      // 实例化echarts对象
      let refcharts = this.$refs.chartsA
      if (refcharts) {

        let myChartDrawer = this.$echarts.init(
          // document.getElementById("chartsA")
          refcharts
        );
        // 绘制环形 
        var option = {
          series: [
            {
              type: "pie",
              radius: ["60%", "80%"],
              center: ["50%", "50%"],
              data: [
                {
                  name: "上月出租率",
                  value: val,
                  itemStyle: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 1,
                      x2: 0,
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#356eff", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#9bfcaf", // 100% 处的颜色
                        },
                      ],

                      global: false, // 缺省为 false
                    },
                  },
                  label: {
                    show: true, //单独显示该数据项
                    formatter: function (obj) {
                      return obj.name + '\n' + obj.percent.toFixed(0) + '%'
                    },
                    color: "#49c3e3", // 100% 处的颜色
                    fontSize: '20',
                    fontWeight: 'bold'
                  },
                },
                {
                  value: total - val,
                  itemStyle: {
                    color: "#ccc",
                  },
                  label: {
                    emphasis: {
                      show: false,
                    },
                  },
                },
              ],
              itemStyle: {
                normal: {},
              },
              label: {
                normal: {
                  //默认不显示数据
                  show: false,
                  position: "center",
                },
                color: "#fff",
              },
            },
          ],
        };
        myChartDrawer.setOption(option);
      } else {
        console.log('drawA渲染dom未渲染')
      }
    },
    drawB (val, total) {

      if (val < 0) {
        val = 0
      }
      if (val > total) {
        val = total
      }
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("chartsB")
      );
      // 绘制环形
      var option = {
        series: [
          {
            type: "pie",
            radius: ["60%", "80%"],
            center: ["50%", "50%"],
            data: [
              {
                name: "当月出租率",
                value: val,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#356eff", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#356eff", // 100% 处的颜色
                      },
                    ],

                    global: false, // 缺省为 false
                  },
                },
                label: {
                  show: true, //单独显示该数据项
                  formatter: function (obj) {
                    return obj.name + '\n' + obj.percent.toFixed(0) + '%'
                  },
                  color: "#49c3e3", // 100% 处的颜色
                  fontSize: '20',
                  fontWeight: 'bold'
                },
              },
              {
                value: total - val,
                itemStyle: {
                  color: "#ccc",
                },
                label: {
                  emphasis: {
                    show: false,
                  },
                },
              },
            ],
            itemStyle: {
              normal: {},
            },
            label: {
              normal: {
                //默认不显示数据
                show: false,
                position: "center",
              },
              color: "#fff",
            },
          },
        ],
      };
      myChartDrawer.setOption(option);
    },
    drawLine (val) {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("myCharts")
      );
      var option = {
        label:{
          color:'#fff'
        },
        // title: {
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '6%',
          // top: '8%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.month,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 800,
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {

              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            }, //
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF",
                fontWeight: 800,
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            },
          }
        ],
        series: [
          {
            // name: '2018年',
            type: 'line',
            data: this.monthNum,
            smooth: true,// 线条平滑
            symbol: 'circle',// 折线点实心
            symbolSize: 6,// 折线点大小
            // 图例
            itemStyle: {
              borderWidth: 2,
              color: 'rgb(24,69,229)',
            },
            label: {
              show: true,
              position: 'top'
            },
            // 线条颜色
            lineStyle: {
              color: 'rgb(24,69,229)'
            },
            // 填充区域颜色
            areaStyle: {
              color: {
                type: 'linear',
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgb(24,69,229)' // 0% 处的颜色
                }, {
                  offset: 0.4, color: 'rgb(24,69,229,.7)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgb(24,69,229,0)' // 100% 处的颜色
                }],
                global: false
              }
            }
          },
        ]
      };
      myChartDrawer.setOption(option);

    },
  },
};
</script>

<style lang="scss" scoped>
#leftLevel {
  padding: 0.2rem;
  height: 100%;
  min-width: 7rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
    .text {
      font-size: 0.25rem;
      font-weight: bold;
      color: #fff;
      width: 100%;
      text-align: left;
      // border-bottom: 0.03rem solid #ffffff;
      padding-bottom: 0.12rem;
    }
  }
  .num_title {
    width: 100%;
    height: 25%;
    display: flex;
    .number {
      flex: 1;
      display: flex;
      font-size: 0.3rem;
      font-weight: bold;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .unit {
        font-size: 0.2rem;
      }
      p {
        font-size: 0.2rem;
        padding-bottom: 0.2rem;
      }
      div {
        font-size: 0.4rem;
      }
    }
  }
  .datav {
    display: flex;
    height: 60%;
    width: 100%;
    .chart_one {
      flex: 1;
    }
  }
}
::v-deep#leftLoops {
  padding: 0.2rem;
  height: 100%;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
  }
  .d-flex pt-2 pl-2 {
    height: 5%;
  }
  .text {
    // color: #c3cbde;
    font-size: 0.25rem;
    font-weight: bold;
    color: #fff;
  }
  .color0 {
    color: #ff5722;
  }

  .color1 {
    color: #409eff;
  }
}
.histogram-info {
  position: relative;
  height: 95%;
}
#myCharts {
  width: 100%;
  height: 100%;
  min-height: 220px;
  box-sizing: border-box;
  margin: 0px auto;
}
</style>
